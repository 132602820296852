import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExclamationCircleIcon, ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/solid';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '../../components/BasicTable';
import { twoDecimalsPercentFormat } from '../../Format';
import { useAssetById, useDeleteComponent } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { Component } from '../../models/Component';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';

export const ComponentList: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: { components, assetClass, valuationType } = {} as Asset, refetch } = useAssetById(parseInt(id));
  // const [componentList] = useState<Component[]>(components);
  const [confirmationBox, setConfirmationBox] = useState<boolean>(false);
  const [componentId, setComponentId] = useState<number>(0);
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const removeComponent = useDeleteComponent();

  const deleteComponent = (): void => {
    removeComponent.mutateAsync({ componentId, id: Number(id) }).then((data) => {
      data && refetch();
    });
  };

  const openConfirmationBox = (cid: number): void => {
    setComponentId(cid);
    setConfirmationBox(true);
  };

  const columns = React.useMemo<ColumnDef<Component>[]>(
    () => [
      {
        header: 'Component ',
        accessorKey: 'name',
        meta: {
          className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({
          row: {
            original: { id, componentId, name },
          },
        }): React.ReactElement => (
          <>
            <Link to={`/components/${id}`} className="btn-link">
              {name}
            </Link>
            {componentId && <div className="flex italic text-xs font-light">{componentId}</div>}
          </>
        ),
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Sub Type',
        accessorKey: 'subType',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => <div>{row.original.subType}</div>,
      },
      ...(valuationType !== 'Direct Cost'
        ? [
            {
              header: 'Apportionment',
              accessorKey: 'apportionmentPct',
              meta: {
                headerClassName: 'text-right whitespace-normal',
                className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
              },
              cell: ({ row }): string => twoDecimalsPercentFormat.format(row.original.apportionmentPct),
            },
          ]
        : []),

      {
        header: 'Score',
        accessorKey: 'consumptionScore',
        meta: {
          headerClassName: 'text-right',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): React.ReactElement => (
          <div>
            {row.original.consumptionScore}
            {row.original.previousScore && row.original.previousScore > 0 && (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-light bg-gray-50 text-gray-500">
                {row.original.previousScore}
              </span>
            )}
          </div>
        ),
      },
      {
        header: '',
        accessorKey: 'actions',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div className="flex space-x-1">
            {row.original.hasErrors && <ExclamationCircleIcon className="h-4 w-4 text-red-500" />}
            {row.original.hasWarnings && <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400" />}
          </div>
        ),
      },
      ...(!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass)
        ? [
            {
              header: (): React.ReactElement => (
                <Link to={`/components/${0}/edit/${id}`} className="btn-link">
                  <PlusIcon className="ml-3 h-4 w-4" />
                </Link>
              ),
              accessorKey: 'id',
              meta: {
                className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
              },
              cell: ({ row }): React.ReactElement => (
                <div className="flex flex-row space-x-2">
                  <Link to={`/components/${row.original.id || 0}/edit`} className="btn-link">
                    <PencilSquareIcon className="text-indigo-600 h-4 w-4" />
                  </Link>
                  <button onClick={(): void => openConfirmationBox(row.original.id || 0)} className="btn-link">
                    <TrashIcon className="text-indigo-600 h-4 w-4 hover:text-red-700" />
                  </button>
                </div>
              ),
            },
          ]
        : []),
    ],
    [readOnlyAssetClasses]
  );

  return (
    <div className="m-3">
      <BasicTable
        columns={columns}
        data={components || []}
        isLoading={components === undefined}
        tablePageSize={10}
        showHead
      />
      <DeleteConfirmation
        itemType="component"
        isOpen={confirmationBox}
        setOpen={setConfirmationBox}
        deleteAction={deleteComponent}
      />
    </div>
  );
};
