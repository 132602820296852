import React, { useState, SetStateAction, Dispatch } from 'react';
import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { AdjustmentsHorizontalIcon, CalendarIcon, PlusIcon } from '@heroicons/react/24/solid';
import { twoDecimalsPercentFormat } from '../../../Format';
import { Asset, ComponentHierarchyItem } from '../../../models/Asset';
import { ComponentEdit } from '../../ComponentListEdit';
import { useToast } from '../../../contexts/ToastContext';
import { Component } from '../../../models/Component';
import { NewComponentModal } from '../../NewComponentModal';
import { NewApportionmentModal } from '../../NewApportionmentModal';
import { Job } from '../../../models/Job';

interface ComponentListQuickEditProps {
  control: Control<Asset, object>;
  errors: any;
  componentList: ComponentHierarchyItem[];
  setComponentList: Dispatch<SetStateAction<ComponentHierarchyItem[]>>;
  asset: Asset;
  job: Job;
  isDirectCost: boolean;
  register: UseFormRegister<Asset>;
  resetField: UseFormResetField<Asset>;
  watch: UseFormWatch<Asset>;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
  reset: UseFormReset<Asset>;
}

const ComponentListQuickEdit: React.FC<ComponentListQuickEditProps> = ({
  control,
  errors,
  componentList,
  setComponentList,
  asset,
  job,
  isDirectCost,
  register,
  resetField,
  getValues,
  watch,
  setValue,
  reset,
}) => {
  const { addToast } = useToast();
  const { fields, append, remove } = useFieldArray({ control, name: 'components' });
  const [showDates, setShowDates] = useState<boolean>(false);
  const [existingComponents, setExistingComponents] = useState<Component[]>([]);
  const [reapportionedComponents, setReapportionedComponents] = useState<Component[]>([]);
  const [isNewApportModalOpen, setIsNewApportModalOpen] = useState<boolean>(false);
  const [isNewComponentModalOpen, setIsNewComponentModalOpen] = useState<boolean>(false);

  const addComponent = (selectedComponent: ComponentHierarchyItem): void => {
    const comptToAppend = componentList.find((comp) => comp.id === selectedComponent.id) || ({} as ComponentHierarchyItem);
    append({
      id: comptToAppend.id,
      nameId: comptToAppend.id,
      name: comptToAppend.name,
      apportionmentPct: comptToAppend.apportionment,
      acquisitionDate: getValues('acquisitionDate') != null ? getValues('acquisitionDate') : undefined,
      decommissionDate: getValues('decommissionDate') != null ? getValues('decommissionDate') : undefined,
    } as Component);
    setComponentList(componentList.filter((comp) => comp.id !== selectedComponent.id));
  };

  const recalculateApportionmnet = (): void => {
    const oldComponents: Component[] = getValues('components');
    const newComponents: Component[] = [] as Component[];
    const apportionmentTotal = Math.round(oldComponents.reduce((prev, curr) => prev + curr.apportionmentPct, 0) * 100) / 100;
    let newApportionmentTotal = 0;
    if (apportionmentTotal !== 100) {
      const apportionmentRatio = +(100 / apportionmentTotal).toFixed(1);
      oldComponents.forEach((comp) => {
        const compName = componentList.find((comlis) => comlis.id === comp.nameId)?.name || '';
        const reapportionedComp = {
          ...comp,
          name: comp.name === undefined ? compName : comp.name,
          apportionmentPct: +(comp.apportionmentPct * apportionmentRatio).toFixed(1),
        } as Component;
        newComponents.push(reapportionedComp);
      });
      newApportionmentTotal = newComponents.reduce((prev, curr) => prev + curr.apportionmentPct, 0);
      const structureComponent = newComponents.find((comp) => comp.name.includes('02 Structure'));
      const subStructureComponent = newComponents.find((comp) => comp.name.includes('01 Sub-Structure'));
      const compHighestApport = newComponents.reduce((prev, current) =>
        prev.apportionmentPct > current.apportionmentPct ? prev : current
      );
      if (newApportionmentTotal !== 100) {
        const leftoverApportionment = +(100 - newApportionmentTotal).toFixed(1);
        if (structureComponent !== undefined) {
          const structureCompIndex = newComponents.findIndex((comp) => comp.nameId === structureComponent.nameId);
          const newReallocatedApportionment = newComponents[structureCompIndex].apportionmentPct + leftoverApportionment;
          newComponents[structureCompIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        } else if (subStructureComponent !== undefined) {
          const subStructureCompIndex = newComponents.findIndex((comp) => comp.nameId === subStructureComponent.nameId);
          const newReallocatedApportionment = newComponents[subStructureCompIndex].apportionmentPct + leftoverApportionment;
          newComponents[subStructureCompIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        } else {
          const compHighestApportIndex = newComponents.findIndex((comp) => comp.nameId === compHighestApport.nameId);
          const newReallocatedApportionment = newComponents[compHighestApportIndex].apportionmentPct + leftoverApportionment;
          newComponents[compHighestApportIndex].apportionmentPct = +newReallocatedApportionment.toFixed(1);
        }
      }
      setExistingComponents(oldComponents);
      setReapportionedComponents(newComponents);
      setIsNewApportModalOpen(true);
    } else if (apportionmentTotal === 100) {
      addToast('Total apportionment is equal to 100%');
    }
  };

  return (
    <>
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                  Component
                </th>
                <th scope="col" className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                  Type
                </th>
                <th scope="col" className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                  Sub-Type
                </th>
                <th scope="col" className="px-3 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                  Score
                </th>
                <th scope="col" className="px-1 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                  Apport
                </th>
                <th scope="col" className="px-1 py-2 text-right text-xs font-medium uppercase tracking-wide text-gray-500">
                  Gross
                </th>
                {showDates && (
                  <>
                    <th
                      scope="col"
                      className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Acquisition
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Decomission
                    </th>
                  </>
                )}

                <th scope="col" className="relative px-3 py-3 text-right flex flex-row justify-center space-x-4">
                  <button
                    type="button"
                    className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                    onClick={(): void => setShowDates(!showDates)}
                    title="Change Acquisition/Decomission Dates"
                  >
                    <CalendarIcon className="h-4 w-4" />
                  </button>
                  {componentList.length > 0 && (
                    <button
                      type="button"
                      className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => {
                        setIsNewComponentModalOpen(true);
                      }}
                    >
                      <PlusIcon className="h-4 w-4" />
                    </button>
                  )}

                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {fields?.map((field, index) => (
                <ComponentEdit
                  key={field.id}
                  assetAssumption={asset.assumptions}
                  remove={remove}
                  {...{
                    index,
                    field,
                    typeError:
                      errors !== undefined && errors.components !== undefined
                        ? errors.components[index]?.typeId?.message
                        : undefined,
                    subTypeError:
                      errors !== undefined && errors.components !== undefined
                        ? errors.components[index]?.subTypeId?.message
                        : undefined,
                    dateError:
                      errors !== undefined && errors.components !== undefined
                        ? errors.components[index]?.decommissionDate?.message
                        : undefined,
                    control,
                    register,
                    componentList,
                    job,
                    setComponentList,
                    showDates,
                    getValues,
                    setValue,
                    reset,
                    isDirectCost,
                    watch,
                    resetField,
                  }}
                />
              ))}
            </tbody>
            <tfoot className="bg-gray-50">
              <tr>
                <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                <td className="px-2 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                <td className="px-1 py-2 text-xs font-medium uppercase tracking-wide text-gray-500" />
                <td
                  className={`px-2 pt-3 pb-2 text-right text-sm font-medium tracking-wide text-gray-500 ${
                    errors?.apportionmentTotal && 'text-red-600'
                  }`}
                >
                  {twoDecimalsPercentFormat.format(
                    getValues('components').reduce((prev, curr) => prev + curr.apportionmentPct, 0) / 100 || 0
                  )}{' '}
                </td>
                <td className="px-1 py-2 text-right text-xs font-medium uppercase text-gray-500">
                  {' '}
                  <button
                    type="button"
                    onClick={(): void => {
                      recalculateApportionmnet();
                    }}
                    disabled={getValues('components')?.length === 0}
                    className="group flex rounded-md disabled:opacity-50 items-center w-full text-xs btn btn-secondary"
                  >
                    <AdjustmentsHorizontalIcon className="w-6 h-6 mr-2" /> Reapportion
                  </button>
                </td>

                {showDates && (
                  <>
                    <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                    <td className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500" />
                  </>
                )}

                <td className="px-2 py-2 text-right lg:space-x-2" />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <NewComponentModal
        isOpen={isNewComponentModalOpen}
        componentList={componentList}
        setOpen={setIsNewComponentModalOpen}
        addComponent={addComponent}
      />
      <NewApportionmentModal
        isOpen={isNewApportModalOpen}
        setOpen={setIsNewApportModalOpen}
        newComponents={reapportionedComponents}
        oldComponents={existingComponents}
        setValue={setValue}
      />

    </>
  );
};

export default ComponentListQuickEdit;
