import { PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { currencyFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { ReplacementCostSummary } from '../../models/ReplacementCostSummary';
import { Roles } from '../../models/Role';
import { ReplacementCostType, useDeleteAssetReplacementCost, useDeleteComponentReplacementCost } from '../../hooks/UseAsset';

interface Props {
  parentId: number;
  type: ReplacementCostType;
  replacementCostList: ReplacementCostSummary[];
  editable: boolean;
  refetch(): void;
}

const ReplacementCostList: React.FC<Props> = ({ replacementCostList, type, editable, refetch, parentId }) => {
  const { id = '' } = useParams<{ id: string }>();
  const url = type === ReplacementCostType.Asset ? 'assetreplacementcosts' : 'componentreplacementcosts';
  const [replacementCosts, setReplacementCosts] = useState<ReplacementCostSummary[]>(replacementCostList);
  const { roles } = useAuth();
  const deleteComponentReplacementCost = useDeleteComponentReplacementCost();
  const deleteAssetReplacementCost = useDeleteAssetReplacementCost();
  const totalGross: number = replacementCosts.reduce((acc: number, curr: ReplacementCostSummary) => curr.gross + acc, 0);
  const deleteReplacementCost = (rcid: number): void => {
    if (type === ReplacementCostType.Component) {
      deleteComponentReplacementCost.mutateAsync({ componentId: parentId, id: rcid }).then((data) => {
        data && refetch();
      });
    } else {
      deleteAssetReplacementCost.mutateAsync({ assetId: parentId, id: rcid }).then((data) => {
        data && refetch();
      });
    }
  };

  useEffect(() => {
    setReplacementCosts(replacementCostList);
  }, [replacementCostList]);

  return (
    <div className=" align-middle inline-block min-w-full">
      <div className="shadow  rounded-md overflow-hidden border-b border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Area
              </th>
              <th
                scope="col"
                className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Qty
              </th>
              <th
                scope="col"
                className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Total
              </th>
              <th
                scope="col"
                className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Adopted Rate
              </th>
              <th
                scope="col"
                className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Index
              </th>
              <th
                scope="col"
                className="px-4 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Gross
              </th>

              <th scope="col" className="whitespace-nowrap px-4 py-3 text-right text-sm font-medium">
                {!roles.includes(Roles.Viewer) && editable && (
                  <Link to={`/${url}/${0}/edit/${id}`} className="btn-link" type="button">
                    <PlusIcon className="h-4 w-4" />
                  </Link>
                )}
                <span className="sr-only">Create</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {replacementCosts?.map((replacementCost, index) => (
              <ReplacementCostListRow
                key={index}
                replacementCost={replacementCost}
                assetId={parseInt(id)}
                type={type}
                roles={roles}
                editable={editable}
                deleteReplacementCost={deleteReplacementCost}
              />
            ))}
            {/* {obsolescenseAdjustmentPct !== undefined && obsolescenseAdjustmentPct > 0 && (
                      <tr className="bg-white">
                        <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900" />
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500" />
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500" />
                        <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right col-span-2" />
                        <td className="px-4 py-3 whitespace-nowrap text-sm font-normal text-gray-900 text-right ">
                          {`Obsolescence Adj (${obsolescenseAdjustmentPct})`}%
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-normal text-gray-700">????</td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium text-gray-700" />
                      </tr>
                    )} */}
            {replacementCostList.length > 1 && (
              <tr className="bg-white">
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900" />
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500" />
                <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500" />
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right col-span-2" />
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900" />
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right ">Total</td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium text-gray-700">
                  {currencyFormat.format(totalGross)}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium text-gray-700" />
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
    </div>
  );
};

interface ReplacementCostListRowProps {
  replacementCost: ReplacementCostSummary;
  assetId?: number;
  componentId?: number;
  type: ReplacementCostType;
  roles: string[];
  editable: boolean;
  deleteReplacementCost(id: number): void;
}

const ReplacementCostListRow: React.FC<ReplacementCostListRowProps> = ({
  replacementCost: { id, name, length, width, area, total, quantity, adoptedRate, gross, indexationPct },
  type,
  roles,
  editable,
  deleteReplacementCost,
  assetId,
}) => {
  const url = type === ReplacementCostType.Asset ? 'assetreplacementcosts' : 'componentreplacementcosts';
  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="overflow-ellipsis px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 col-span-1">
        <Link to={`/${url}/${id}`} className="btn-link">
          {name}
        </Link>
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm  text-gray-500">
        {area}{' '}
        {length !== undefined && width !== undefined && (
          <span className="italic text-xs">
            ({length} x {width})
          </span>
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">{quantity}</td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-500">{total}</td>
      {/* <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right " /> */}

      <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-normal text-gray-500">
        {currencyFormat.format(adoptedRate)}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-normal text-gray-500">
        {twoDecimalsPercentFormat.format(indexationPct)}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-normal text-gray-500">
        {currencyFormat.format(gross)}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium text-gray-700">
        {!roles.includes(Roles.Viewer) && editable && (
          <div className="space-x-4 flex flex-row justify-end">
            <Link to={`/${url}/${id}/edit/${assetId}`} className="text-right btn-link">
              <PencilSquareIcon className="h-4 w-4" />
            </Link>
            <button onClick={(): void => deleteReplacementCost(id)} className="text-right btn-link">
              <TrashIcon className="h-4 w-4 hover:text-red-700" />
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ReplacementCostList;
