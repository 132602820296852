import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useForm } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { useApi } from '../../contexts/ApiContext';
import { BasicTable } from '../../components/BasicTable';
import { User } from '../../models/User';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';

const UserList: React.FC<{}> = () => {
  const api = useApi();
  const [users, setUsers] = useState<User[]>([] as User[]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const { addToast } = useToast();
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    api
      .get(`/api/User/List?admin=${true}`)
      .then(({ data }) => {
        setUsers(data.filter((user: User) => user.id !== 1));
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const removeUser = (): void => {
    api
      .post(`/api/User/Delete`, { userId: id })
      .then(({ data }) => {
        setUsers(data.filter((user: User) => user.id !== 2075));
        addToast(`Successfully deleted user`);
      })
      .catch((error) => {
        addToast(`Unable to remove user`, ToastType.Error);
      });
  };

  const reinviteUser = (userId: number): void => {
    console.log('userId', userId);
    api
      .post(`/api/User/Reinvite`, { userId })
      .then(() => {
        addToast(`Successfully Reinvited User`);
      })
      .catch((error) => {
        addToast(`Unable to Reinvite User`, ToastType.Error);
      });
  };

  const openDeleteModal = (userId: number): void => {
    setId(userId);
    setIsDeleteModalOpen(true);
  };

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: 'Name ',
        accessorKey: 'userName',
        meta: {
          className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => {
          const fullName = `${row.original.firstName ?? ''} ${row.original.lastName ?? ''}`;
          return (
            <div className="flex flex-row space-x-2">
              <Link className="btn-link" to={`/users/${row.original.id}`}>
                {fullName.length > 2 ? fullName : row.original.userName}
              </Link>
              {row.original.isSearchable && (
                <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  <MagnifyingGlassIcon className="h-3 w-3 text-gray-400" />
                </span>
              )}
            </div>
          );
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Confirmed',
        accessorKey: 'emailConfirmed',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }) =>
          row.original.emailConfirmed ? (
            <CheckIcon className="h-5 w-5 text-green-400" />
          ) : (
            <button onClick={() => reinviteUser(row.original.id)} className="btn btn-link">
              Resend Invite
            </button>
          ),
      },
      {
        header: 'Client',
        accessorKey: 'client',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: '',
        accessorKey: 'id',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }) => (
          <button
            className="inline-flex items-center justify-center 
              font-medium rounded-md text-red-500 
              hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
            onClick={(): void => openDeleteModal(row.original.id)}
          >
            Delete
          </button>
        ),
      },
    ],
    []
  );

  type FormData = {
    searchText: string;
  };

  const { handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(() => {
    api
      .get(`/api/User/List?admin=${true}&SearchText=${searchText}`)
      .then(({ data }) => {
        setUsers(data.filter((user: User) => user.id !== 2075));
      })
      .catch((error) => {
        console.log('error', error);
      });
  });

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Users</h1>
        </div>
      </div>
      <div className="m-4 bg-white shadow overflow-hidden sm:rounded-md flex ">
        <form onSubmit={onSubmit} className="flex-grow m-2">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="flex my-1 ml-1 relative min-w-max">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              {/* Heroicon name: search */}
              <svg
                className="mr-3 h-4 w-4 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              name="searchText"
              onChange={(e): void => setSearchText(e.target.value)}
              value={searchText}
              className="mt-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
              placeholder="Search Users"
            />
            <input type="submit" value="Search" className="m-1 btn btn-primary" />
          </div>
        </form>
      </div>
      <div className="mx-4 pt-4">
        <BasicTable columns={columns} data={users} />
        {/* <JobModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} /> */}
      </div>
      <DeleteConfirmation
        itemType="User"
        isOpen={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        deleteAction={removeUser}
      />
    </>
  );
};

interface Props {
  users: User[];
}

export default UserList;
