import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Disclosure } from '@headlessui/react';
import { ColumnDef } from '@tanstack/react-table';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useApi } from '../../contexts/ApiContext';

import { AssetAssumptions } from '../../models/AssetAssumptions';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, twoDecimalsPercentFormat } from '../../Format';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClass: HierarchyType;
  assetType: HierarchyType;
  assetSubType: HierarchyType;
  valuationClassId: number;
};

const AssetAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const {
    assetAssumptions,
    assetAssumptionsSearchText,
    getAssetAssumptions,
    selectedAssetClassAssetAssumptionSearchContext,
    setSelectedAssetClassAssetAssumptionSearchContext,
    selectedAssetTypeAssetAssumptionSearchContext,
    setSelectedAssetTypeAssetAssumptionSearchContext,
    selectedAssetSubTypeAssetAssumptionSearchContext,
    setSelectedAssetSubTypeAssetAssumptionSearchContext,
    assetAssumptionsCurrentPage,
    setAssetAssumptionsCurrentPage,
    isSearchingAssetAssumptions,
    assetClassList,
    tableAssetAssumptionsPageSize,
    setAssetAssumptionsTablePageSize,
  } = useAssumptionsSearch();

  // const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [selectedAssetClassType, setSelectedAssetClassType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetClassQuery, setAssetClassQuery] = useState<string>('');

  const [selectedAssetType, setSelectedAssetType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetTypeQuery, setAssetTypeQuery] = useState<string>('');

  const [selectedAssetSubType, setSelectedAssetSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetSubTypeQuery, setAssetSubTypeQuery] = useState<string>('');

  const [valuationClassList, setValuationClassList] = useState<HierarchyType[]>([] as HierarchyType[]);

  // const [selectedAssetType, setSelectedAssetType] = useState<number>(0);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<FormData>();
  const columns = React.useMemo<ColumnDef<AssetAssumptions>[]>(
    () => [
      {
        header: 'Asset Class',
        accessorKey: 'assetClass',
        meta: {
          headerClassName: 'px-3 py-3',
          className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetClass}
          </Link>
        ),
      },
      {
        header: 'Asset Type',
        accessorKey: 'assetType',
        meta: {
          headerClassName: 'px-3 py-3',
          className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetType}
          </Link>
        ),
      },
      {
        header: 'Asset Sub Type',
        accessorKey: 'assetSubType',
        meta: {
          headerClassName: 'px-3 py-3',
          className: 'px-3 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/assetassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetSubType}
          </Link>
        ),
      },
      {
        header: 'No. of Assets',
        accessorKey: 'assetsCount',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Financial Hierarchy',
        accessorKey: 'financialAssetClass',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <>
            <div className="whitespace-nowrap text-sm leading-5">{row.original.financialAssetClass}</div>
            {row.original.financialAssetSubClass && (
              <div className="flex italic text-xs font-light">{row.original.financialAssetSubClass}</div>
            )}
          </>
        ),
      },
      {
        header: 'Valuation Class',
        accessorKey: 'valuationClass.name',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Unit Rate',
        accessorKey: 'unitRate',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${currencyFormat.format(row.original.unitRate)}`,
      },
      {
        header: 'Locality Factor',
        accessorKey: 'localityFactorPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.localityFactorPct)}`,
      },
      {
        header: 'Index Yr 1',
        accessorKey: 'indices[0]',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.indices[0])}`,
      },
      {
        header: 'Index Yr 2',
        accessorKey: 'indices[1]',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.indices[1])}`,
      },
      {
        header: 'Index Yr 3',
        accessorKey: 'indices[2]',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.indices[2])}`,
      },
      {
        header: 'Index Yr 4',
        accessorKey: 'indices[3]',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.indices[3])}`,
      },
      {
        header: 'Index Yr 5',
        accessorKey: 'indices[4]',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${twoDecimalsPercentFormat.format(row.original.indices[4])}`,
      },
      {
        header: '',
        accessorKey: 'edit ',
        meta: {
          className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>
            {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass) && (
              <NavLink className="btn-link" to={`/assetassumptions/${row.original.id}/edit`}>
                edit
              </NavLink>
            )}
          </div>
        ),
      },
    ],
    [readOnlyAssetClasses]
  );

  const onSubmit = handleSubmit(({ searchText, assetClass, assetType, assetSubType, valuationClassId }) => {
    getAssetAssumptions(
      searchText,
      selectedAssetClassType.value,
      selectedAssetType.value,
      selectedAssetSubType.value,
      valuationClassId
    );
    setSearchComplete(true);
  });

  const filteredAssetClasses =
    assetClassQuery === ''
      ? assetClassList
      : assetClassList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetClassQuery.toLowerCase())
        );

  const filteredAssetTypes =
    assetTypeQuery === ''
      ? assetTypeList
      : assetTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetTypeQuery.toLowerCase())
        );

  const filteredAssetSubTypes =
    assetSubTypeQuery === ''
      ? assetSubTypeList
      : assetSubTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetSubTypeQuery.toLowerCase())
        );

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchValuationClasses = (): void => {
    api
      .post('/api/ValuationClass/List', {})
      .then(({ data }) => {
        setValuationClassList(data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClassType && selectedAssetClassType.value > 0) {
      fetchAssetTypes(selectedAssetClassType.value);
    }
  }, [selectedAssetClassType]);

  useEffect(() => {
    if (selectedAssetType && selectedAssetType.value > 0) {
      fetchAssetSubTypes(selectedAssetType.value);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    fetchValuationClasses();
    setSelectedAssetClassType(selectedAssetClassAssetAssumptionSearchContext);
    setSelectedAssetType(selectedAssetTypeAssetAssumptionSearchContext);
    setSelectedAssetSubType(selectedAssetSubTypeAssetAssumptionSearchContext);
  }, []);

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <>
          {/* <!-- Projects table (small breakpoint and up) --> */}
          <div className="hidden sm:block">
            <div className="align-middle inline-block min-w-full">
              <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
                <div className="flex-1 min-w-0 justify-between flex">
                  <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Asset Assumptions</h1>
                </div>

                <div className="pb-2 flex items-end flex-wrap space-x-2">
                  <div className="flex-grow">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="mt-1 relative min-w-max">
                      <div
                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        aria-hidden="true"
                      >
                        {/* Heroicon name: search */}
                        <svg
                          className="mr-3 h-4 w-4 text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        {...register('searchText')}
                        id="searchText"
                        defaultValue={assetAssumptionsSearchText}
                        className="py-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <Combobox
                    name="assetClass"
                    value={selectedAssetClassType}
                    onChange={(value): void => {
                      setSelectedAssetClassType(value);
                      setSelectedAssetClassAssetAssumptionSearchContext(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Class</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetClassQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetClasses.map((assetClass) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetClass.value}
                            value={assetClass}
                          >
                            {assetClass.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>

                  <Combobox
                    name="assetType"
                    value={selectedAssetType}
                    onChange={(value): void => {
                      setSelectedAssetType(value);
                      setSelectedAssetTypeAssetAssumptionSearchContext(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetTypeQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetTypes.map((assetType) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetType.value}
                            value={assetType}
                          >
                            {assetType.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>
                  <Combobox
                    name="assetSubType"
                    value={selectedAssetSubType}
                    onChange={(value): void => {
                      setSelectedAssetSubType(value);
                      setSelectedAssetSubTypeAssetAssumptionSearchContext(value);
                    }}
                  >
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                      <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                        <ComboboxInput
                          className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                          onChange={(event): void => setAssetSubTypeQuery(event.target.value)}
                          displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                          onFocus={(e) => e.target.select()}
                          autoComplete="off"
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </ComboboxButton>
                      </div>
                      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                        {filteredAssetSubTypes.map((assetType) => (
                          <ComboboxOption
                            className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                            key={assetType.value}
                            value={assetType}
                          >
                            {assetType.label}
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>
                  <div className="pt-1 mx-2">
                    <label className="block text-sm font-medium text-gray-700">Valuation Class</label>
                    <select
                      defaultValue="All"
                      {...register('valuationClassId')}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value={0}>All</option>
                      {valuationClassList.map((ac) => (
                        <option key={ac.value} value={ac.value}>
                          {ac.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input value="Search" type="submit" className="mt-1 ml-1 mr-3 btn btn-primary" />

                  {/* <Disclosure.Button as="div">
                    <button
                      type="button"
                      className="m-1  inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-10 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                    >
                      <ChevronDoubleDownIcon
                        className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                          !open && 'rotate-180'
                        } ease-in-out duration-150`}
                      />
                    </button>
                  </Disclosure.Button> */}
                </div>
                {/* <div className="flex justify-end" /> */}
              </form>
              <div
                className={`mx-3 ${
                  !isSearchingAssetAssumptions && assetAssumptionsSearchText !== '' && assetAssumptions.length <= 0
                }`}
              >
                <BasicTable
                  columns={columns}
                  data={assetAssumptions}
                  currentPage={assetAssumptionsCurrentPage}
                  setCurrentPage={setAssetAssumptionsCurrentPage}
                  isLoading={isSearchingAssetAssumptions}
                  tablePageSize={tableAssetAssumptionsPageSize}
                  setTablePageSize={setAssetAssumptionsTablePageSize}
                />

                {!isSearchingAssetAssumptions && searchComplete && assetAssumptions.length <= 0 && (
                  <div className="text-center mt-14 pt-3 pb-6">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Asset Assumptions Found</h3>
                    <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default AssetAssumptionsSearch;
