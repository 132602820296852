import { formatDistance, subDays } from 'date-fns';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '../components/BasicTable';
import { Notification, useNotification } from '../contexts/NotificationContext';

export const NotificationList = (): JSX.Element => {
  const { notifications, clearNotifications } = useNotification();

  const columns = React.useMemo<ColumnDef<Notification>[]>(
    () => [
      {
        header: 'Message',
        accessorKey: 'detail',
        meta: {
          className: 'px-2 py-2.5 text-sm leading-5',
        },
      },
      {
        header: 'Type',
        accessorKey: 'isFail',
        meta: {
          className: 'px-2 py-2.5 text-sm leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>
            {row.original.isFail ? (
              <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                Error
              </span>
            ) : (
              <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                Success
              </span>
            )}
          </div>
        ),
      },
      {
        header: 'Message Received',
        accessorKey: 'createdOn',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>{formatDistance(row.original?.createdOn || new Date(), new Date(), { addSuffix: true })}</div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Notifications</h1>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <span className="shadow-sm">
              <button onClick={(): void => clearNotifications()} className="btn btn-secondary">
                Clear Notifications
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="mx-4 pt-4">
        <BasicTable data={notifications} columns={columns} />
      </div>
    </>
  );
};
