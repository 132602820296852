import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { XMarkIcon, PhotoIcon, TrashIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '../../components/BasicTable';
import { Asset } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { useToast } from '../../contexts/ToastContext';
import { Content } from '../../models/Content';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import ImageModal from '../Asset/ImageModal';
import { ImageDisplay } from '../../models/Image';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';

export const ContentList: React.FC<{}> = () => {
  const { roles } = useAuth();
  const { id = '' } = useParams<{ id: string }>();
  const api = useApi();
  const { addToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imagesDisplay, setImagesDisplay] = useState<ImageDisplay[]>([]);
  const { data: { contents } = {} as Asset, refetch } = useAssetById(parseInt(id));
  const [contentsArray, setContentsArray] = useState<Content[]>([]);
  const [confirmationBox, setConfirmationBox] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [contentsId, setContentsId] = useState<number>(0);

  useEffect(() => {
    if (contents !== undefined && contents.length > 0) {
      setContentsArray(contents);
    }
  }, [contents]);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<Comment>({
    reValidateMode: 'onBlur',
  });

  //   const onSubmit = (Comment: Comment) => {
  //     const noteToUpdate: Note = {
  //       id: 0,
  //       tag: noteTag,
  //       detail: Comment.noteDetail,
  //     };
  //     api
  //       .post('/api/Asset/UpdateNote', { assetId: id, note: noteToUpdate })
  //       .then(({ data }) => {
  //         setNotesArray(data);
  //         addToast('Updated note');
  //       })
  //       .catch((error) => {});
  //   };

  const openConfirmationBox = (nid: number): void => {
    setContentsId(nid);
    setConfirmationBox(true);
  };

  const openEditModal = (nid: number): void => {
    setContentsId(nid);
    setEditModal(true);
  };

  const removeContents = (): void => {
    api.post('/api/Asset/DeleteContents', { id: contentsId }).then(
      (result) => {
        result && refetch();
        addToast('Deleted Contents');
      },
      (error) => {
        addToast('Unable to delete Contents');
      }
    );
  };
  console.log('contents', contents);

  const columns = React.useMemo<ColumnDef<Content>[]>(
    () => [
      {
        header: 'Item',
        accessorKey: 'item',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 font-medium',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Rate',
        accessorKey: 'appliedRate',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
      },
      {
        header: 'Quantity',
        accessorKey: 'quantity',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
      },
      {
        header: 'Total',
        accessorKey: '',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): JSX.Element => <div>{(row.original.quantity || 1) * (row.original.rate || 1)}</div>,
      },
      {
        header: (): React.ReactElement => (
          <Link to={`/contents/${0}/edit/${id}`} className="inline-flex items-center">
            <PlusIcon className="mr-5 h-4 w-4 text-indigo-600" />
          </Link>
        ),
        accessorKey: 'id',
        headerClassName: 'text-right whitespace-normal',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        cell: ({ row }): React.ReactElement => (
          <div className="flex flex-row justify-end space-x-4">
            <button
              onClick={(): void => {
                setIsModalOpen(true);
                setImagesDisplay(row.original?.images || []);
              }}
              disabled={row.original?.images?.length == 0}
            >
              <PhotoIcon
                className={`h-4 w-4 ${
                  row.original?.images?.length == 0 ? 'text-gray-300' : 'text-indigo-600'
                }  font-medium rounded-md focus:outline-none`}
              />
            </button>
            <Link to={`/contents/${row.original.id}/edit`} className="btn-link">
              <PencilSquareIcon className="text-indigo-600 h-4 w-4" />
            </Link>
            {!roles.includes(Roles.Viewer) && (
              <button onClick={(): void => openConfirmationBox(row.original.id)}>
                <TrashIcon className="h-4 w-4 text-indigo-600  font-medium rounded-md hover:text-red-700 focus:outline-none" />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  // const updateNote = (): void => {
  //   const noteToUpdate: Note = {
  //     id: noteId,
  //     tag: noteTag,
  //     detail: noteDetail,
  //   };
  //   api
  //     .post('/api/Asset/UpdateNote', { assetId: id, note: noteToUpdate })
  //     .then(({ data }) => {
  //       setNotesArray(data);
  //       addToast('Updated note');
  //     })
  //     .catch((error) => {});
  // };

  // useEffect(() => {
  //   if (notesArray.length > 0) {
  //     const noteTypesUsed = notesArray.map((note) => note.tag);
  //     setNoteTypesAvailable(noteTypes.filter((noteType) => !noteTypesUsed.includes(noteType)));
  //   }
  // }, [notesArray]);

  // useEffect(() => {
  //   if (noteTypesAvailable.length > 0) {
  //     setNoteTag(noteTypesAvailable[0]);
  //   }
  // }, [noteTypesAvailable]);

  return (
    <>
      {/* <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-6 sm:px-6">
            <ul className="space-y-8 max-h-112 overflow-y-auto">
              {contentsArray.map((contents) => (
                <li key={contents.id}>
                  <div className="flex space-x-3">
                    <div>
                      <div className="text-sm font-medium text-gray-900">{contents.item}</div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>{contents.type}</p>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>Rate: {contents.appliedRate}</p>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>Quantity: {contents.quantity}</p>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>Cost: {contents.quantity * contents.appliedRate}</p>
                      </div>
                      <div className="mt-2 flex space-x-8 text-sm">
                        {contents?.images && contents?.images.length > 0 && (
                          <button
                            onClick={(): void => {
                              setIsModalOpen(true);
                              setImagesDisplay(contents?.images || []);
                            }}
                            className="inline-flex items-center gap-x-1.5 rounded-full bg-white py-1.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-indigo-700"
                          >
                            <PhotoIcon className="h-5 w-5 text-indigo-600  font-medium rounded-md focus:outline-none" />
                            Images
                          </button>
                        )}
                        {!roles.includes(Roles.Viewer) && (
                          <button
                            onClick={(): void => openConfirmationBox(contents.id)}
                            className="inline-flex items-center gap-x-1.5 rounded-full bg-white py-1.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-red-700"
                          >
                            <TrashIcon className="h-5 w-5 text-indigo-600  font-medium rounded-md focus:outline-none" />
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {imagesDisplay && imagesDisplay.length > 0 && (
          <ImageModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} images={imagesDisplay} index={0} />
        )}
      </div> */}
      <div className="m-3">
        <BasicTable columns={columns} data={contents || []} isLoading={contents === undefined} tablePageSize={10} showHead />
      </div>
      {imagesDisplay && imagesDisplay.length > 0 && (
        <ImageModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} images={imagesDisplay} index={0} />
      )}
      <DeleteConfirmation
        isOpen={confirmationBox}
        setOpen={setConfirmationBox}
        itemType="Contents"
        deleteAction={removeContents}
      />
      {/* <NoteModal
        isOpen={editModal}
        setOpen={setEditModal}
        setNoteDetail={setNoteDetail}
        noteTag={noteTag}
        setNoteTag={setNoteTag}
        notesAvailable={noteTypesAvailable}
        noteDetail={noteDetail}
        updateNote={updateNote}
      /> */}
    </>
  );
};
