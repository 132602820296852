import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Disclosure } from '@headlessui/react';
import { ChevronDoubleDownIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useApi } from '../../contexts/ApiContext';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, percentFormat } from '../../Format';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ComponentAssumptionsWithHierarchy } from '../../models/ComponentAssumptions';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClass: HierarchyType;
  assetType: HierarchyType;
  assetSubType: HierarchyType;
  componentName: HierarchyType;
  componentType: HierarchyType;
  componentSubType: HierarchyType;
};

const ComponentAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const {
    componentAssumptions,
    componentAssumptionsSearchText,
    getComponentAssumptions,
    selectedAssetClassComponentAssumptionSearchContext,
    setSelectedAssetClassComponentAssumptionSearchContext,
    selectedAssetTypeComponentAssumptionSearchContext,
    setSelectedAssetTypeComponentAssumptionSearchContext,
    selectedAssetSubTypeComponentAssumptionSearchContext,
    setSelectedAssetSubTypeComponentAssumptionSearchContext,
    selectedComponentNameComponentAssumptionSearchContext,
    setSelectedComponentNameComponentAssumptionSearchContext,
    selectedComponentTypeComponentAssumptionSearchContext,
    setSelectedComponentTypeComponentAssumptionSearchContext,
    selectedComponentSubTypeComponentAssumptionSearchContext,
    setSelectedComponentSubTypeComponentAssumptionSearchContext,
    componentAssumptionsCurrentPage,
    setComponentAssumptionsCurrentPage,
    isSearchingComponentAssumptions,
    assetClassList,
    tableComponentAssumptionsPageSize,
    setComponentAssumptionsTablePageSize,
  } = useAssumptionsSearch();
  const [effect, setEffect] = useState<boolean>(false);
  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentNameList, setComponentNameList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentTypeList, setComponentTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [componentSubTypeList, setComponentSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);

  const [selectedAssetClass, setSelectedAssetClass] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetClassQuery, setAssetClassQuery] = useState<string>('');

  const [selectedAssetType, setSelectedAssetType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetTypeQuery, setAssetTypeQuery] = useState<string>('');

  const [selectedAssetSubType, setSelectedAssetSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetSubTypeQuery, setAssetSubTypeQuery] = useState<string>('');

  const [selectedComponentName, setSelectedComponentName] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [componentNamesQuery, setComponentNamesQuery] = useState<string>('');

  const [selectedComponentType, setSelectedComponentType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [componentTypeQuery, setComponentTypeQuery] = useState<string>('');

  const [selectedComponentSubType, setSelectedComponentSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [componentSubTypeQuery, setComponentSubTypeQuery] = useState<string>('');

  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const columns = React.useMemo<ColumnDef<ComponentAssumptionsWithHierarchy>[]>(
    () => [
      {
        header: 'Asset Class',
        accessorKey: 'assetClass',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id || 0}`} className="btn-link">
            {row.original.assetClass}
          </Link>
        ),
      },
      {
        header: 'Asset Type',
        accessorKey: 'assetType',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id || 0}`} className="btn-link">
            {row.original.assetType}
          </Link>
        ),
      },
      {
        header: 'Asset Sub Type',
        accessorKey: 'assetSubType',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetSubType}
          </Link>
        ),
      },
      {
        header: 'Component Name',
        accessorKey: 'componentName',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link">
            {row.original.componentName}
          </Link>
        ),
      },
      {
        header: 'Component Type',
        accessorKey: 'componentType',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link font-medium">
            {row.original.componentType}
          </Link>
        ),
      },
      {
        header: 'Component Sub Type',
        accessorKey: 'componentSubType',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-sm text-left leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/componentassumptions/${row.original.id}`} className="btn-link font-medium">
            {row.original.componentSubType}
          </Link>
        ),
      },
      {
        header: 'No. of Components',
        accessorKey: 'componentsCount',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Unit Rate',
        accessorKey: 'unitRate',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${currencyFormat.format(row.original.unitRate)}`,
      },
      {
        header: 'Locality Factor',
        accessorKey: 'localityFactorPct',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.localityFactorPct)}`,
      },
      {
        header: 'Long Life Split',
        accessorKey: 'longLifePct',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.longLifePct)}`,
      },

      {
        header: 'UL Short',
        accessorKey: 'ulShortMin',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${row.original.ulShortMin} - ${row.original.ulShortMax} yrs`,
      },
      {
        header: 'RV Short / Long',
        accessorKey: 'rvPctShort',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string =>
          `${percentFormat.format(row.original.rvPctShort)} / ${percentFormat.format(row.original.rvPctLong)}`,
      },
      {
        header: 'UL Long',
        accessorKey: 'ulLong',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      // {
      //   header: 'Residual Value Long',
      //   headerClassName: 'px-3 py-3 text-right',
      //   accessorKey: 'rvPctLong',
      //   className: 'px-3 py-3 text-right whitespace-nowrap text-sm leading-5',
      //   cell: ({ value }): string => `${percentFormat.format(value)} / ${percentFormat.format(value)}`,
      // },
      {
        header: 'Depreciation Policy',
        accessorKey: 'depreciationPolicy',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Obsolescence Profile',
        accessorKey: 'obsolescenceProfile',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: '',
        accessorKey: 'edit ',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>
            {!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass) && (
              <NavLink className="btn-link" to={`/componentassumptions/${row.original.id}/edit`}>
                edit
              </NavLink>
            )}
          </div>
        ),
      },
    ],
    [readOnlyAssetClasses]
  );

  const filteredAssetClasses =
    assetClassQuery === ''
      ? assetClassList
      : assetClassList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetClassQuery.toLowerCase())
        );

  const filteredAssetTypes =
    assetTypeQuery === ''
      ? assetTypeList
      : assetTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetTypeQuery.toLowerCase())
        );

  const filteredAssetSubTypes =
    assetSubTypeQuery === ''
      ? assetSubTypeList
      : assetSubTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetSubTypeQuery.toLowerCase())
        );

  const filteredComponentNames =
    componentNamesQuery === ''
      ? componentNameList
      : componentNameList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(componentNamesQuery.toLowerCase())
        );

  const filteredComponentTypes =
    componentTypeQuery === ''
      ? componentTypeList
      : componentTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(componentTypeQuery.toLowerCase())
        );

  const filteredComponentSubTypes =
    componentSubTypeQuery === ''
      ? componentSubTypeList
      : componentSubTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(componentSubTypeQuery.toLowerCase())
        );

  const onSubmit = handleSubmit(
    ({ searchText, assetClass, assetType, assetSubType, componentName, componentType, componentSubType }) => {
      getComponentAssumptions(
        searchText,
        selectedAssetClass.value,
        selectedAssetType.value,
        selectedAssetSubType.value,
        selectedComponentName.value,
        selectedComponentType.value,
        selectedComponentSubType.value
      );
      setSearchComplete(true);
    }
  );

  const resetSearch = (): void => {
    setSelectedAssetClass({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetSubType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentName({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentType({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentSubType({ value: 0, label: 'All' } as HierarchyType);
    setValue('searchText', '');
    setSelectedAssetClassComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetTypeComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
    setSelectedAssetSubTypeComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentNameComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentTypeComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
    setSelectedComponentSubTypeComponentAssumptionSearchContext({ value: 0, label: 'All' } as HierarchyType);
  };

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchComponentNames = (assetSubTypeId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentNames?AssetSubTypeId=${assetSubTypeId}`)
      .then(({ data }) => {
        setComponentNameList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchComponentTypes = (componentNameId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentTypes?ComponentNameId=${componentNameId}`)
      .then(({ data }) => {
        setComponentTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchComponentSubTypes = (componentTypeId: number): void => {
    api
      .get(`/api/ComponentHierarchy/GetComponentSubTypes?ComponentTypeId=${componentTypeId}`)
      .then(({ data }) => {
        setComponentSubTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClass && selectedAssetClass.value > 0) {
      fetchAssetTypes(selectedAssetClass.value);
    }
  }, [selectedAssetClass]);

  useEffect(() => {
    if (selectedAssetType && selectedAssetType.value > 0) {
      fetchAssetSubTypes(selectedAssetType.value);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    if (selectedAssetSubType && selectedAssetSubType.value > 0) {
      fetchComponentNames(selectedAssetSubType.value);
    }
  }, [selectedAssetSubType]);

  useEffect(() => {
    if (selectedComponentName && selectedComponentName.value > 0) {
      fetchComponentTypes(selectedComponentName.value);
    }
  }, [selectedComponentName]);

  useEffect(() => {
    if (selectedComponentType && selectedComponentType.value > 0) {
      fetchComponentSubTypes(selectedComponentType.value);
    }
  }, [selectedComponentType]);

  useEffect(() => {
    setSelectedAssetClass(selectedAssetClassComponentAssumptionSearchContext);
    setSelectedAssetType(selectedAssetTypeComponentAssumptionSearchContext);
    setSelectedAssetSubType(selectedAssetSubTypeComponentAssumptionSearchContext);
    setSelectedComponentName(selectedComponentNameComponentAssumptionSearchContext);
    setSelectedComponentType(selectedComponentTypeComponentAssumptionSearchContext);
    setSelectedComponentSubType(selectedComponentSubTypeComponentAssumptionSearchContext);
  }, []);

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <div>
          <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
            <div className="mb-5 flex-1 min-w-0 justify-between flex">
              <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Component Assumptions</h1>
            </div>
            <div className="pb-2 flex items-end flex-wrap">
              <div className="flex-grow mr-3">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="m-1 relative min-w-max">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                    {/* Heroicon name: search */}
                    <svg
                      className="mr-3 h-4 w-4 text-gray-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    {...register('searchText')}
                    id="searchText"
                    defaultValue={componentAssumptionsSearchText}
                    className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                    placeholder="Search"
                  />
                </div>
              </div>
              <Disclosure.Button as="div">
                <button
                  type="button"
                  className="m-1  inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-10 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                >
                  <ChevronDoubleDownIcon
                    className={`ml-auto h-3 w-5 transform-gpu text-gray-600 group-hover:text-gray-400 ${
                      !open && 'rotate-180'
                    } ease-in-out duration-150`}
                  />
                </button>
              </Disclosure.Button>
              <input type="submit" value="Search" className="my-auto mx-3 btn btn-primary" />
            </div>
            <div className="flex justify-end" />
            <Disclosure.Panel as="div" className="space-y-2">
              <div className="flex flex-grow justify-start mt-2 space-x-8">
                <Combobox
                  name="assetClass"
                  value={selectedAssetClass}
                  onChange={(value): void => {
                    setSelectedAssetClass(value);
                    setSelectedAssetClassComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Class</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetClassQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetClasses.map((assetClass) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetClass.value}
                          value={assetClass}
                        >
                          {assetClass.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>

                <Combobox
                  name="assetType"
                  value={selectedAssetType}
                  onChange={(value): void => {
                    setSelectedAssetType(value);
                    setSelectedAssetTypeComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetTypes.map((assetType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetType.value}
                          value={assetType}
                        >
                          {assetType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="assetSubType"
                  value={selectedAssetSubType}
                  onChange={(value): void => {
                    setSelectedAssetSubType(value);
                    setSelectedAssetSubTypeComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Sub Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetSubTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetSubTypes.map((assetType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetType.value}
                          value={assetType}
                        >
                          {assetType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="componentName"
                  value={selectedComponentName}
                  onChange={(value): void => {
                    setSelectedComponentName(value);
                    setSelectedComponentNameComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Component Name</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setComponentNamesQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredComponentNames.map((componentName) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={componentName.value}
                          value={componentName}
                        >
                          {componentName.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="componentType"
                  value={selectedComponentType}
                  onChange={(value): void => {
                    setSelectedComponentType(value);
                    setSelectedComponentTypeComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Component Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setComponentTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredComponentTypes.map((componentType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={componentType.value}
                          value={componentType}
                        >
                          {componentType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="componentSubType"
                  value={selectedComponentSubType}
                  onChange={(value): void => {
                    setSelectedComponentSubType(value);
                    setSelectedComponentSubTypeComponentAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Component Sub Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setComponentSubTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredComponentSubTypes.map((componentSubType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={componentSubType.value}
                          value={componentSubType}
                        >
                          {componentSubType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <button
                  onClick={(): void => {
                    resetSearch();
                    setEffect(true);
                  }}
                  type="button"
                  className="inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm h-9 self-end font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0"
                >
                  <ArrowPathIcon
                    className={`${effect && 'animate-[spin_1s_linear]'} h-4 w-4 text-gray-600`}
                    onAnimationEnd={(): void => setEffect(false)}
                  />
                </button>
              </div>
            </Disclosure.Panel>
          </form>
          <div
            className={`mx-3 ${
              !isSearchingComponentAssumptions && componentAssumptionsSearchText !== '' && componentAssumptions.length <= 0
            }`}
          >
            <BasicTable
              columns={columns}
              data={componentAssumptions}
              currentPage={componentAssumptionsCurrentPage}
              setCurrentPage={setComponentAssumptionsCurrentPage}
              isLoading={isSearchingComponentAssumptions}
              tablePageSize={tableComponentAssumptionsPageSize}
              setTablePageSize={setComponentAssumptionsTablePageSize}
            />

            {!isSearchingComponentAssumptions && searchComplete && componentAssumptions.length <= 0 && (
              <div className="text-center mt-14 pt-3 pb-6">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">No Component Assumptions Found</h3>
                <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
              </div>
            )}
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default ComponentAssumptionsSearch;
