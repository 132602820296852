import React from 'react';
import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface GeneralInformationProps {
  control: any;
  register: any;
  errors: any;
  id: string;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ control, register, errors, id }) => (
  <div className="space-y-6 bg-white">
    <div className="grid grid-cols-6 gap-6">
       <div className="col-span-6 sm:col-span-6">
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          {...register('name')}
          autoComplete="off"
          className={`mt-1 block w-full rounded-md ${
            errors?.name ? 'border-rose-600' : ''
          } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
        />
      </div>

       <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm font-medium text-gray-700">Asset ID</label>
        <input
          type="text"
          {...register('reference')}
          className={`mt-1 block w-full rounded-md ${id !== '0' ? `disabled:opacity-50` : ''} ${
            errors?.reference ? 'border-rose-600' : ''
          } border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm font-medium text-gray-700">Entity ID</label>
        <input
          type="text"
          {...register('customerReference')}
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Acquisition Date</label>
        <Controller
          control={control}
          name="acquisitionDate"
          render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
            <ReactDatePicker
              onChange={onChange}
              onBlur={onBlur}
              name="acquisitionDate"
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              selected={value}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Decommission Date</label>
        <Controller
          control={control}
          name="decommissionDate"
          render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
            <ReactDatePicker
              onChange={onChange}
              onBlur={onBlur}
              name="decommissionDate"
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              selected={value}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Indexed From Date</label>
        <Controller
          control={control}
          name="indexedFromDate"
          render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
            <ReactDatePicker
              onChange={onChange}
              onBlur={onBlur}
              name="indexedFromDate"
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              selected={value}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm font-medium text-gray-700">Valuer</label>
        <input
          defaultValue=""
          type="text"
          {...register('valuer')}
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm font-medium text-gray-700">Inspector</label>
        <input
          defaultValue=""
          type="text"
          {...register('inspectorName')}
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  </div>
);

export default GeneralInformation;
