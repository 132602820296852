import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../contexts/ApiContext';
import { Lease } from '../../models/Lease';
import { MaintenancePlan } from '../../models/MaintenancePlan';

interface MaintenancePlanSummaryProps {
  assetId: number;
}

const MaintenancePlanSummary: React.FC<MaintenancePlanSummaryProps> = ({ assetId }) => {
  const api = useApi();
  const [maintenancePlans, setMaintenancePlans] = useState<MaintenancePlan[]>([]);

  useEffect(() => {
    api.post<MaintenancePlan[]>(`/api/MaintenancePlan/List`, { assetId }).then(({ data }) => {
      setMaintenancePlans(data);
    });
  }, [assetId]);

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Maintenance Plans
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <Link to={`/maintenancePlans/0/edit/${assetId}`} className="btn btn-link">
                      Create Maintenance Plan
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {maintenancePlans.map((maintenancePlan) => (
                  <tr key={maintenancePlan.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <Link to={`/maintenancePlans/${maintenancePlan.id}`} className="btn-link">
                        {maintenancePlan.item}
                      </Link>
                    </td>
                    <tr className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePlanSummary;
