import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import { User } from '../../models/User';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { BasicTable } from '../../components/BasicTable';
import { Client } from '../../models/Client';

const UserView: React.FC<{}> = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User>({} as User);

  const columns = useMemo<ColumnDef<Client>[]>(
    () => [
      {
        header: 'Name ',
        accessorKey: 'displayName',
        meta: {
          className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        },
      },
      {
        header: 'Is Admin',
        accessorKey: 'administratorId',
        meta: {
          className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element =>
          Number(row.original.administratorId) === Number(id) ? (
            <CheckIcon className="h-5 w-5 text-green-400" />
          ) : (
            <XMarkIcon className="h-5 w-5 text-red-400" />
          ),
      },
    ],
    []
  );

  useEffect(() => {
    api
      .get(`/api/User/Get?userId=${id}`)
      .then(({ data }) => {
        setUser(data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  if (Object.keys(user).length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Users</p>
      </div>
    );
  }
  const { firstName, lastName, userName, email, roles, emailConfirmed, clients, isSearchable } = user;
  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="bg-gray-50 shadow rounded-md rounded-md overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
            <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <span className="shadow-sm">
                <Link to={`/users/${id}/edit`} className="btn btn-link">
                  Edit
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Username</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userName}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">First Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{firstName}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Last Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{lastName}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Confirmed</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{emailConfirmed ? 'Yes' : 'No'}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Searchable</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{isSearchable ? 'Yes' : 'No'}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Role</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex space-x-4">
                {roles.map((role) => (
                  <div
                    key={role.id}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 h-6"
                  >
                    {role.name}
                  </div>
                ))}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Clients</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <BasicTable columns={columns} data={clients} tablePageSize={5} />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserView;
