import GoogleMapReact from 'google-map-react';
import { Dispatch, useState } from 'react';
import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ReactComponent as LocationMarker } from '../../../icons/LocationMarker.svg';
import { AutoCompleteWithControl } from '../../AutoComplete';
import { useToast } from '../../../contexts/ToastContext';

interface Center {
  lat: number;
  lng: number;
}

interface LocationProps {
  defaultCenter: Center;
  setCenter: Dispatch<React.SetStateAction<Center>>;
  center: Center;
  setValue(string, any): void;
  control: any;
  register: any;
  suburbOptions: string[];
  cityOptions: string[];
  facilityOptions: string[];
  subFacilityOptions: string[];
  watchLatitude: number | undefined;
  watchLongitude: number | undefined;
}

const Location: React.FC<LocationProps> = ({
  defaultCenter,
  setCenter,
  center,
  setValue,
  control,
  register,
  suburbOptions,
  cityOptions,
  facilityOptions,
  subFacilityOptions,
  watchLatitude,
  watchLongitude
}) => {
  const { addToast } = useToast();
  const [draggable, setDraggable] = useState<boolean>(true);
  const zoom = 17;

  const Marker: React.FC<{ lat: number; lng: number }> = () => (
    <div className="absolute top-1/2 left-1/2 pb-6 select-none transform -translate-x-1/2 -translate-y-1/2 ">
      <LocationMarker className="h-8 w-8 text-red-600" />
    </div>
  );

  return (
    <div className="space-y-6 bg-white">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">Address Line</label>
          <input
            defaultValue=""
            type="text"
            id="streetAddress"
            {...register('streetAddress')}
            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="mb-1 block text-sm font-medium text-gray-700">Suburb</label>
          <AutoCompleteWithControl name="suburb" control={control} rules={{ required: true }} labels={suburbOptions} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">Town / City</label>
          <AutoCompleteWithControl name="city" control={control} rules={{ required: true }} labels={cityOptions} />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">Facility</label>
          <AutoCompleteWithControl name="facility" control={control} rules={{ required: true }} labels={facilityOptions} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">Sub-Facility</label>
          <AutoCompleteWithControl
            name="subFacility"
            control={control}
            rules={{ required: false }}
            labels={subFacilityOptions}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">Latitude</label>
          <input
            type="text"
            {...register('latitude', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border border-gray-300 disabled:opacity-50 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">Longtitude</label>
          <input
            type="text"
            {...register('longitude', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border border-gray-300 disabled:opacity-50 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      {watchLatitude && watchLongitude ? (
        <div className="w-full h-[60vh] relative">
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            options={(map) => ({
              streetViewControl: true,
              controlSize: 22,
              mapTypeControl: true,
              mapTypeId: map.MapTypeId.SATELLITE,
              mapTypeControlOptions: {
                style: map.MapTypeControlStyle.DROPDOWN,
                position: map.ControlPosition.TOP_CENTER,
                mapTypeIds: [map.MapTypeId.ROADMAP, map.MapTypeId.SATELLITE],
              },
            })}
            onChildMouseDown={(a, b, c) => {
              setDraggable(false);
            }}
            onChildMouseUp={(a, b, c) => {
              setDraggable(true);
              setCenter({
                lat: c.lat,
                lng: c.lng,
              });
              setValue('latitude', c.lat);
              setValue('longitude', c.lng);
              addToast(`Asset coordinates changed to latitude: ${c.lat} and longitude: ${c.lng}`);
            }}
            onChildMouseMove={(a, b, c) => {
              setValue('latitude', c.lat);
              setValue('longitude', c.lng);
            }}
            bootstrapURLKeys={{ key: 'AIzaSyAIa1oCSlhCZOpu9lAdfqENa_r0oOKuyKU' }}
            defaultCenter={{
              lat: defaultCenter.lat,
              lng: defaultCenter.lng,
            }}
            center={{
              lat: center.lat,
              lng: center.lng,
            }}
            draggable={draggable}
            defaultZoom={zoom}
          >
            <Marker lat={watchLatitude} lng={watchLongitude} />
          </GoogleMapReact>
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-42 bg-white mb-4 py-2 px-4 rounded-md shadow-sm border border-gray-300 flex items-center">
            <div className="flex-shrink-0 self-center mr-1">
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-800">Drag marker to change coordinates</h3>
          </div>
        </div>
      ) : (
        <div className="w-full h-96 relative bg-gray-50 flex justify-center items-center">
          <div className="flex items-start m-auto">
            <div className="flex-shrink-0 self-center">
              <GlobeAsiaAustraliaIcon className="h-12 w-12 text-gray-700" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-gray-800">Invalid or missing coordinates</h3>
              <div className="mt-1 text-sm text-gray-700">
                <p>Unable to show map view due to invalid or empty coordinates</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Location;
