import React, { Fragment, useEffect, useRef, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useApi } from '../../contexts/ApiContext';
import { InsuranceAssumptions } from '../../models/InsuranceAssumptions';
import { BasicTable } from '../../components/BasicTable';
import { HierarchyType } from '../../models/HierarchyType';
import { currencyFormat, percentFormat } from '../../Format';
import { useToast } from '../../contexts/ToastContext';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { useClient } from '../../contexts/ClientContext';
import { useAssumptionsSearch } from '../../contexts/AssumptionsContext';

type FormData = {
  searchText: string;
  assetClassId: number;
  assetTypeId: number;
  assetSubTypeId: number;
};

const InsuranceAssumptionsSearch: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const [open, setOpen] = useState<boolean>(true);
  const { addToast } = useToast();
  const {
    insuranceAssumptions,
    insuranceAssumptionsSearchText,
    getInsuranceAssumptions,
    selectedAssetClassInsuranceAssumptionSearchContext,
    setSelectedAssetClassInsuranceAssumptionSearchContext,
    selectedAssetTypeInsuranceAssumptionSearchContext,
    setSelectedAssetTypeInsuranceAssumptionSearchContext,
    selectedAssetSubTypeInsuranceAssumptionSearchContext,
    setSelectedAssetSubTypeInsuranceAssumptionSearchContext,
    insuranceAssumptionsCurrentPage,
    setInsuranceAssumptionsCurrentPage,
    isSearchingInsuranceAssumptions,
    assetClassList,
    tableInsuranceAssumptionsPageSize,
    setInsuranceAssumptionsTablePageSize,
  } = useAssumptionsSearch();

  const [assetTypeList, setAssetTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<HierarchyType[]>([] as HierarchyType[]);
  const [valuationClassList, setValuationClassList] = useState<HierarchyType[]>([] as HierarchyType[]);

  const [selectedAssetClassType, setSelectedAssetClassType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetClassQuery, setAssetClassQuery] = useState<string>('');

  const [selectedAssetType, setSelectedAssetType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetTypeQuery, setAssetTypeQuery] = useState<string>('');

  const [selectedAssetSubType, setSelectedAssetSubType] = useState<HierarchyType>({
    value: 0,
    label: 'All',
  } as HierarchyType);
  const [assetSubTypeQuery, setAssetSubTypeQuery] = useState<string>('');

  const [searchComplete, setSearchComplete] = useState<boolean>(false);

  const fetchAssetTypes = (assetClassId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetTypes?AssetClassId=${assetClassId}`)
      .then(({ data }) => {
        setAssetTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchAssetSubTypes = (assetTypeId: number): void => {
    api
      .get(`/api/AssetHierarchy/GetAssetSubTypes?AssetTypeId=${assetTypeId}`)
      .then(({ data }) => {
        setAssetSubTypeList([{ value: 0, label: 'All' } as HierarchyType].concat(data.filter((n) => n)));
      })
      .catch((error) => {});
  };

  const fetchValuationClasses = (): void => {
    api
      .post('/api/ValuationClass/List', {})
      .then(({ data }) => {
        setValuationClassList(data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedAssetClassType && selectedAssetClassType.value > 0) {
      fetchAssetTypes(selectedAssetClassType.value);
    }
  }, [selectedAssetClassType]);

  useEffect(() => {
    if (selectedAssetType && selectedAssetType.value > 0) {
      fetchAssetSubTypes(selectedAssetType.value);
    }
  }, [selectedAssetType]);

  useEffect(() => {
    fetchValuationClasses();
    setSelectedAssetClassType(selectedAssetClassInsuranceAssumptionSearchContext);
    setSelectedAssetType(selectedAssetTypeInsuranceAssumptionSearchContext);
    setSelectedAssetSubType(selectedAssetSubTypeInsuranceAssumptionSearchContext);
  }, []);

  const {
    register,
    setValue,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<FormData>();

  const columns = React.useMemo<ColumnDef<InsuranceAssumptions>[]>(
    () => [
      {
        header: 'Asset Class',
        accessorKey: 'assetClass',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id || 0}`} className="btn-link">
            {row.original.assetClass}
          </Link>
        ),
      },
      {
        header: 'Asset Type',
        accessorKey: 'assetType',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetType}
          </Link>
        ),
      },
      {
        header: 'Asset Sub Type',
        accessorKey: 'assetSubType',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/insuranceassumptions/${row.original.id}`} className="btn-link">
            {row.original.assetSubType}
          </Link>
        ),
      },
      {
        header: 'No. of Assets',
        accessorKey: 'assetsCount',
        meta: {
          headerClassName: 'px-3 py-3 text-left',
          className: 'px-3 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },

      {
        header: 'Lead Build Months',
        accessorKey: 'leadBuildMonths',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Demolition Months',
        accessorKey: 'demoMonths',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Escalation Factor',
        accessorKey: 'escalationFactorPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.escalationFactorPct)}`,
      },
      {
        header: 'Professional Fees',
        accessorKey: 'professionalFeesPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.professionalFeesPct)}`,
      },
      {
        header: 'Debris Removal Rate',
        accessorKey: 'debrisRemovalPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.debrisRemovalPct)}`,
      },
      {
        header: 'Debris Removal Minimum',
        accessorKey: 'debrisRemovalMinimum',
        meta: {
          headerClassName: 'px-3.5 py-3 text-left',
          className: 'px-3.5 py-3 text-left whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${currencyFormat.format(row.original.debrisRemovalMinimum)}`,
      },
      {
        header: '',
        accessorKey: 'edit ',
        meta: {
          className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element | null => {
          if (!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass)) {
            return (
              <NavLink className="btn-link" to={`/insuranceassumptions/${row.original.id}/edit`}>
                edit
              </NavLink>
            );
          }
          return null;
        },
      },
    ],
    [readOnlyAssetClasses]
  );

  const filteredAssetClasses =
    assetClassQuery === ''
      ? assetClassList
      : assetClassList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetClassQuery.toLowerCase())
        );

  const filteredAssetTypes =
    assetTypeQuery === ''
      ? assetTypeList
      : assetTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetTypeQuery.toLowerCase())
        );

  const filteredAssetSubTypes =
    assetSubTypeQuery === ''
      ? assetSubTypeList
      : assetSubTypeList.filter((hierarchyType: HierarchyType) =>
          hierarchyType.label.toLowerCase().includes(assetSubTypeQuery.toLowerCase())
        );

  const onSubmit = handleSubmit(({ searchText, assetClassId, assetTypeId, assetSubTypeId }) => {
    getInsuranceAssumptions(searchText, selectedAssetClassType.value, selectedAssetType.value, selectedAssetSubType.value);
    setSearchComplete(true);
  });

  return (
    <Disclosure>
      {({ open }): JSX.Element => (
        <div className="hidden sm:block">
          <div className="align-middle inline-block min-w-full">
            <form onSubmit={onSubmit} className="p-5 mx-3 my-5 bg-white shadow-md rounded-md">
              <div className="flex-1 min-w-0 justify-between flex">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Insurance Assumptions</h1>
              </div>
              <div className="pb-2 flex items-end flex-wrap space-x-2">
                <div className="flex-grow pt-1">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="mt-1 relative min-w-max">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                      {/* Heroicon name: search */}
                      <svg
                        className="mr-3 h-4 w-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      {...register('searchText')}
                      id="searchText"
                      defaultValue={insuranceAssumptionsSearchText}
                      className="py-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <Combobox
                  name="assetClass"
                  value={selectedAssetClassType}
                  onChange={(value): void => {
                    setSelectedAssetClassType(value);
                    setSelectedAssetClassInsuranceAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Class</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetClassQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetClasses.map((assetClass) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetClass.value}
                          value={assetClass}
                        >
                          {assetClass.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="assetType"
                  value={selectedAssetType}
                  onChange={(value): void => {
                    setSelectedAssetType(value);
                    setSelectedAssetTypeInsuranceAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetTypes.map((assetType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetType.value}
                          value={assetType}
                        >
                          {assetType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <Combobox
                  name="assetSubType"
                  value={selectedAssetSubType}
                  onChange={(value): void => {
                    setSelectedAssetSubType(value);
                    setSelectedAssetSubTypeInsuranceAssumptionSearchContext(value);
                  }}
                >
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Asset Type</label>
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
                      <ComboboxInput
                        className="w-full rounded-md border-gray-100 bg-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event): void => setAssetSubTypeQuery(event.target.value)}
                        displayValue={(hierarchyType: HierarchyType): string => hierarchyType?.label}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pt-1 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </ComboboxButton>
                    </div>
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                      {filteredAssetSubTypes.map((assetType) => (
                        <ComboboxOption
                          className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
                          key={assetType.value}
                          value={assetType}
                        >
                          {assetType.label}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
                <input type="submit" value="Search" className="mt-1 ml-1 mr-3 btn btn-primary" />
              </div>
            </form>
            <div
              className={`mx-3 ${
                !isSearchingInsuranceAssumptions && insuranceAssumptionsSearchText !== '' && insuranceAssumptions.length <= 0
              }`}
            >
              <BasicTable
                columns={columns}
                data={insuranceAssumptions}
                currentPage={insuranceAssumptionsCurrentPage}
                setCurrentPage={setInsuranceAssumptionsCurrentPage}
                isLoading={isSearchingInsuranceAssumptions}
                tablePageSize={tableInsuranceAssumptionsPageSize}
                setTablePageSize={setInsuranceAssumptionsTablePageSize}
              />

              {!isSearchingInsuranceAssumptions && searchComplete && insuranceAssumptions.length <= 0 && (
                <div className="text-center mt-14 pt-3 pb-6">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">No Insurance Assumptions Found</h3>
                  <p className="mt-1 text-sm text-gray-500">Please revise search parameters</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default InsuranceAssumptionsSearch;
