import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { BlobServiceClient } from '@azure/storage-blob';
import { CloudArrowDownIcon, DocumentIcon, DocumentTextIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { format } from 'date-fns';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from './BasicTable';
import { convertDate } from '../Helper';
import DocumentImportOptions from './DocumentOptions';
import { DocumentType } from '../models/DocumentType';
import { EntityType } from '../models/EntityType';
import { ToastType, useToast } from '../contexts/ToastContext';
import DocumentInput from './DocumentInput';
import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';
import { DeleteConfirmation } from './DeleteConfirmation';

export interface Document {
  id: string;
  name: string;
  type: DocumentType;
  uploadDate: Date;
  url: string;
}

interface Props {
  entity: EntityType;
  entityId: number;
  entityGuid?: string;
  open: boolean;
  setOpen(open: boolean): void;
}

const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

const DocumentSlide: React.FC<Props> = ({ entity, entityId, entityGuid, open, setOpen }) => {
  const { roles, client } = useAuth();
  const [files, setFiles] = useState<FileWithPath[]>([] as FileWithPath[]);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isReport, setIsReport] = useState<boolean | null>(true);
  const [isWorkPaper, setIsWorkPaper] = useState<boolean | null>(true);
  const [isSource, setIsSource] = useState<boolean | null>(true);
  const [isSupport, setIsSupport] = useState<boolean | null>(true);
  const [isRawData, setIsRawData] = useState<boolean | null>(true);
  const [isDeletingDocuments, setIsDeletingDocuments] = useState<boolean>(false);
  const [isExportingDocuments, setIsExportingDocuments] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.Source);
  const { addToast } = useToast();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [blobToDelete, setBlobToDelete] = useState<string>('');
  const [documents, setDocuments] = useState<Document[]>([] as Document[]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([] as Document[]);
  const blobServiceClient: BlobServiceClient = new BlobServiceClient(BLOB_URL);
  const { clientGuid } = useAuth();

  let entityQuery: string;

  if (entity === EntityType.Asset) {
    entityQuery = `assetId = '${entityId}'`;
  } else if (entity === EntityType.Job) {
    entityQuery = `jobId = '${entityId}'`;
  } else if (entity === EntityType.Client) {
    entityQuery = `isClient = 'True'`;
  } else if (entity === EntityType.Lease) {
    entityQuery = `leaseId = '${entityId}'`;
  }

  async function fetchDocs(): Promise<Document[]> {
    const query = `@container='${
      clientGuid ?? entityGuid
    }' AND ${entityQuery} AND type = 'Document' AND documentType >= 'A' AND uploaded > '01-01-1900 00:00:00Z'`;
    const blobResults = blobServiceClient.findBlobsByTags(query);
    const tempDocs: Array<Document> = [];
    try {
      for await (const blob of blobResults) {
        const blobName = blob.name.split('/');
        tempDocs.push({
          id: blob.name,
          url: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`,
          name: `${entity === EntityType.Client ? blob.name : blobName[1]}`,
          type: blob.tags?.documentType as DocumentType,
          uploadDate: convertDate(blob.tags?.uploaded),
        });
      }
    } catch (error) {
      // addToast('Could not load images', ToastType.Error);
      console.error('error', error);
    }
    setDocuments(tempDocs);
    setIsSuccess(false);
    return tempDocs;
  }

  const deleteAction = (blobName: string): void => {
    setIsDeleteOpen(true);
    setBlobToDelete(blobName);
  };

  const deleteDocument = async (): Promise<void> => {
    const container = blobServiceClient.getContainerClient(clientGuid);
    const deleteString =
      entity === EntityType.Job || entity === EntityType.Asset || entity === EntityType.Lease
        ? `${entityId}/${blobToDelete}`
        : `${blobToDelete}`;
    const result = container
      .deleteBlob(deleteString)
      .then(() => {
        addToast(`Deleted ${blobToDelete}`);
        setTimeout(() => {
          fetchDocs()
            .then((data) => console.log('got docs', data))
            .catch((error) => console.error('not got docs', error));
        }, 2050);
      })
      .catch((error) => addToast(`Unable to delete ${blobToDelete}`, ToastType.Error));
  };

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    if (isSuccess && open) {
      fetching();
    }
  }, [isSuccess, open]);

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    fetching();
  }, []);

  const deleteDocuments = async (blobsToDelete: string[]): Promise<void> => {
    const container = blobServiceClient.getContainerClient(clientGuid);
    let deletedDocuments = 0;
    setIsDeletingDocuments(true);
    for (let i = 0; i < blobsToDelete.length; i++) {
      deletedDocuments++;
      await container
        .deleteBlob(blobsToDelete[i])
        .then(() => {})
        .catch((error) => addToast(`Unable to delete ${blobsToDelete[i]}`, ToastType.Error));
    }
    setDocuments(documents.filter(({ id: blobName }) => !blobsToDelete.includes(blobName)));
    setIsDeletingDocuments(false);
    addToast(`Deleted ${deletedDocuments} documents.`);
  };

  const exportDocuments = (blobsToExport: string[]): void => {
    const zip = new JSZip();
    let count = 0;
    const zipFileName = `${client} Documents.zip`;
    setIsExportingDocuments(true);
    blobsToExport.forEach((url, i) => {
      const fileName = blobsToExport[i].split('/')[1];
      JSZipUtils.getBinaryContent(
        `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${url}`,
        async (
          err,
          data:
            | string
            | ArrayBuffer
            | number[]
            | Uint8Array
            | Blob
            | Promise<string | ArrayBuffer | number[] | Uint8Array | Blob>
        ) => {
          if (err) {
            console.log('err', err);
            setIsExportingDocuments(false);
          }
          try {
            zip.file(fileName, data, { binary: true });
            count++;
            if (count == blobsToExport.length) {
              zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, zipFileName);
                setIsExportingDocuments(false);
                addToast(`Downloading ${count} documents as zip.`);
              });
            }
          } catch (e) {
            setIsExportingDocuments(false);
          }
        }
      );
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    setFilteredDocuments(
      documents.filter(
        (document) =>
          (isReport && entity !== EntityType.Job ? true : document.type !== DocumentType.Report) &&
          (isSource ? true : document.type !== DocumentType.Source) &&
          (isSupport ? true : document.type !== DocumentType.Support) &&
          (isWorkPaper ? true : document.type !== DocumentType.WorkPaper) &&
          (isRawData ? true : document.type !== DocumentType.RawData)
      )
    );
  }, [isWorkPaper, documents, isReport, isSource, isSupport, isRawData]);

  useEffect(() => {
    setFilteredDocuments(
      documents.filter(
        (document) =>
          (isReport && entity !== EntityType.Job ? true : document.type !== DocumentType.Report) &&
          (isSource ? true : document.type !== DocumentType.Source) &&
          (isSupport ? true : document.type !== DocumentType.Support) &&
          (isWorkPaper ? true : document.type !== DocumentType.WorkPaper) &&
          (isRawData ? true : document.type !== DocumentType.RawData) &&
          document.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  const columns = React.useMemo<ColumnDef<Document>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          className: 'px-2 py-3 whitespace-normal text-sm leading-5',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          className: 'px-2 py-3 whitespace-normal text-sm leading-5',
        },
      },
      {
        header: 'Uploaded',
        accessorKey: 'uploadDate',
        meta: {
          className: 'px-2 py-3 whitespace-normal text-sm leading-5',
        },
        sortType: 'datetime',
        cell: ({ row }): string => format(row.original.uploadDate, 'dd/MM/yyyy'),
      },
      {
        header: '',
        accessorKey: 'url',
        meta: {
          className: 'px-2 py-3 whitespace-normal text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => (
          <div className="space-x-2 flex justify-end">
            <a href={row.original.url} target="_blank" rel="noreferrer" className="btn-link">
              <CloudArrowDownIcon className="h-4 w-4" />
            </a>
            {!roles.includes(Roles.Viewer) && (
              <button
                type="button"
                onClick={(): void => {
                  deleteAction(row.original.name);
                }}
                className="btn-link"
              >
                <TrashIcon className="h-4 w-4 hover:text-red-700" />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" open={open} onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={(): void => setOpen(false)}
            onKeyDown={(): void => setOpen(false)}
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-medium text-gray-900"> Documents </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={(): void => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-4 flex-1 px-4 sm:px-6">
                      <fieldset className="space-x-4 flex flex-row justify-start mb-2">
                        <legend className="sr-only">Notifications</legend>
                        {entity !== EntityType.Job && (
                          <div className="relative flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                onChange={(e): void => setIsReport(e.target.checked)}
                                defaultChecked
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="comments" className="font-medium text-gray-700">
                                Report
                              </label>
                              <span id="comments-description" className="text-gray-500">
                                <span className="sr-only">New comments </span>
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              onChange={(e): void => setIsSource(e.target.checked)}
                              defaultChecked
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="candidates" className="font-medium text-gray-700">
                              Source
                            </label>
                            <span id="candidates-description" className="text-gray-500">
                              <span className="sr-only">New candidates </span>
                            </span>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              onChange={(e): void => setIsSupport(e.target.checked)}
                              defaultChecked
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Support
                            </label>
                            <span id="offers-description" className="text-gray-500">
                              <span className="sr-only">Offers </span>
                            </span>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              onChange={(e): void => setIsWorkPaper(e.target.checked)}
                              defaultChecked
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Work Paper
                            </label>
                            <span id="offers-description" className="text-gray-500">
                              <span className="sr-only">Offers </span>
                            </span>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              onChange={(e): void => setIsRawData(e.target.checked)}
                              defaultChecked
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Raw Data
                            </label>
                            <span id="offers-description" className="text-gray-500">
                              <span className="sr-only">Offers </span>
                            </span>
                          </div>
                        </div>
                      </fieldset>
                      <div className="py-3">
                        <input
                          type="text"
                          name="textBox"
                          onChange={(e): void => setSearchQuery(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Search reports..."
                        />
                      </div>
                      {!roles.includes(Roles.Viewer) ? (
                        <div>
                          <BasicTable
                            data={filteredDocuments}
                            columns={columns}
                            tablePageSize={5}
                            deleteAction={deleteDocuments}
                            isDeleting={isDeletingDocuments}
                            exportAction={exportDocuments}
                            isExporting={isExportingDocuments}
                            showHead
                            context="Document"
                          />
                        </div>
                      ) : (
                        <BasicTable
                          data={filteredDocuments}
                          columns={columns}
                          tablePageSize={5}
                          exportAction={exportDocuments}
                          isExporting={isExportingDocuments}
                          showHead
                          context="Document"
                        />
                      )}
                      {documents.length === 0 && (
                        <div className="text-center mt-5">
                          <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" />
                          <h3 className="mt-2 text-sm font-medium text-gray-900">No documents available</h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Upload documents by clicking or drag and dropping into the space below
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="px-4 sm:px-6">
                      {Object.entries(files).length !== 0 ? (
                        <DocumentInput
                          files={files}
                          isValid={isValid}
                          closeAction={(): void => setFiles([] as FileWithPath[])}
                          url="/api/Document/Import"
                          parameters={{
                            entity: entity.toString(),
                            entityId: entityId.toString(),
                            documentType: documentType.toString(),
                          }}
                          options={
                            <DocumentImportOptions
                              documentType={documentType}
                              setDocumentType={setDocumentType}
                              isJobDocumentContainer={entity === EntityType.Job}
                            />
                          }
                          reloadAction={async (): Promise<Document[]> => fetchDocs()}
                        />
                      ) : (
                        <div
                          {...getRootProps()}
                          className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                        >
                          <div className="space-y-1 text-center">
                            <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                            <div className="flex text-sm text-gray-600">
                              <div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                <span>Upload multiple files</span>
                                <input
                                  {...getInputProps()}
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                />
                              </div>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">XLSX/DOCX/ZIP up to 100MB</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
        <DeleteConfirmation
          itemType="Document"
          isOpen={isDeleteOpen}
          setOpen={setIsDeleteOpen}
          deleteAction={deleteDocument}
        />
      </Dialog>
    </Transition>
  );
};

export default DocumentSlide;
