import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { AssetAssumptions } from '../../models/AssetAssumptions';
import { HierarchyType } from '../../models/HierarchyType';
import { ValuationProfileRule } from '../../models/ValuationProfile';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

type Variables = { valuationProfileRule: ValuationProfileRule };

export const RuleEdit: React.FC<{}> = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { id = '' } = useParams<{ id: string }>();
  const history = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const RuleSchema = yup.object().shape({
    usefulLife: yup.number().min(0, 'Cannot be a negative number.').required('Useful Life for the client is required'),
  });

  const { mutate } = useMutation((params: Variables) => api.post('/api/ValuationProfileRule/Update', params));

  const { register, handleSubmit, reset, getValues } = useForm<ValuationProfileRule>({
    defaultValues: {
      id: 0,
    },
    reValidateMode: 'onBlur',
    // resolver: yupResolver(ClientSchema),
  });

  const { isLoading, data: assetClasses = [] as HierarchyType[] } = useQuery<HierarchyType[], Error>(
    ['AssetClassList'],
    () => api.get('/api/AssetClass/ListByJob?OpenOnly=true', {}).then((res) => res.data)
  );

  const { isLoading: isLoadingProfiles, data: valuationProfiles = [] as HierarchyType[] } = useQuery<HierarchyType[], Error>(
    ['ValuationProfileList'],
    () => api.post('/api/Lookup/ValuationProfile', {}).then((res) => res.data)
  );

  const {
    isLoading: isLoadingRule,
    data: valuationProfileRule = {
      id: 0,
      assetClassId: 0,
      assetClassName: '',
      usefulLife: 0,
      valuationProfileId: 0,
      valuationProfileName: '',
    },
  } = useQuery<ValuationProfileRule, Error>(['valuationProfileRule', id], () =>
    api.get(`/api/ValuationProfileRule/Get?id=${id}`, {}).then(({ data }) => data)
  );

  useEffect(() => {
    if (Object.keys(valuationProfileRule).length > 0) {
      reset({
        ...valuationProfileRule,
      });
    }
  }, [valuationProfileRule]);

  const onSubmit = (ruleData: ValuationProfileRule): void => {
    setIsSaving(true);
    mutate(
      { valuationProfileRule: ruleData },
      {
        onSuccess: ({ data }) => {
          setIsSaving(false);
          addToast(`${parseInt(id) > 0 ? 'Updated' : 'Created'} Rule`);
          history(`/rules/`);
        },
        onError: (error) => {
          setIsSaving(false);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['valuationProfileRule', id]);
          queryClient.invalidateQueries(['asset']);
          queryClient.invalidateQueries(['component']);
          queryClient.invalidateQueries(['valuationProfileRuleList']);
        },
      }
    );
  };

  if (isLoading || isLoadingProfiles || isLoadingRule) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading</p>
      </div>
    );
  }

  return (
    <form className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <input type="hidden" {...register('id', { valueAsNumber: true })} />
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Valuation Profile Rule</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Asset Class
                </label>
                {parseInt(id) === 0 ? (
                  <select
                    {...register('assetClassId', { valueAsNumber: true })}
                    className="disabled:opacity-50 mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    {assetClasses.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    {...register('assetClassId', { valueAsNumber: true })}
                    className="disabled:opacity-50 mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value={valuationProfileRule.assetClassId}>{valuationProfileRule.assetClassName}</option>
                  </select>
                )}
              </div>

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Useful Life
                </label>
                <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs w-max">
                  <input
                    type="text"
                    {...register('usefulLife', { valueAsNumber: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 text-right block w-full pr-14 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      years
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Valuation Profile
                </label>
                <select
                  {...register('valuationProfileId', { valueAsNumber: true })}
                  className="mt-1 max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {valuationProfiles.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(`/rules`);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {isSaving && (
              <div className="flex space-x-4">
                <Loader className="animate-spin w-5 h-5" />
                <div>Saving</div>
              </div>
            )}

            {!isSaving && 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};
