import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { ToastType, useToast } from './ToastContext';
import { NotificationMessage } from '../models/NotificationMesage';

export interface Notification {
  id: number;
  detail: string;
  isFail: boolean;
  createdOn?: Date;
}
interface NotificationContext {
  notifications: Notification[];
  addNotification(): void;
  clearNotifications(): void;
}

const NotificationContext = createContext<NotificationContext>({} as NotificationContext);

export const NotificationProvider: React.FC<any> = (props) => {
  const [notifications, setNotifications] = useState<Notification[]>([] as Notification[]);
  const [connection, setConnection] = useState<HubConnection>();
  const { accessToken } = useOidcAccessToken();
  const { addToast } = useToast();

  const addNotification = (notification: Notification): void => {
    setNotifications((previousNotifications) => [
      ...previousNotifications,
      {
        id: notifications.length,
        detail: notification.detail,
        createdOn: notification.createdOn,
        isFail: notification.isFail,
      },
    ]);
  };

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${import.meta.env.VITE_AUTH}/notificationhub`, { accessTokenFactory: () => accessToken })
      // .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  const clearNotifications = (): void => {
    setNotifications([]);
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('ReceiveMessage', (notification: NotificationMessage) => {
            console.log('message', notification);
            addNotification({
              id: notifications.length,
              detail: notification.message,
              createdOn: new Date(),
              isFail: notification.isFail,
            });
            addToast(notification.message, notification.isFail ? ToastType.Error : ToastType.Message);
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        clearNotifications,
      }}
      {...props}
    />
  );
};

export const useNotification = (): NotificationContext => useContext(NotificationContext);
export default NotificationProvider;
