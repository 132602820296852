import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { downloadHandler } from '../Helper';
import { MaintenancePlan } from '../models/MaintenancePlan';
import { useApi } from './ApiContext';
import { useAuth } from './AuthContext';
import { ToastType, useToast } from './ToastContext';

interface MaintenancePlanSearchContext {
  maintenancePlans: MaintenancePlan[];
  setMaintenancePlans: (MaintenancePlans: MaintenancePlan[]) => void;
  getMaintenancePlans: (text: string, MaintenancePlanType?: string) => void;
  deleteMaintenancePlans: (MaintenancePlanIds: number[]) => void;
  exportMaintenancePlans: (MaintenancePlanIds: number[]) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  maintenancePlanType: string;
  setMaintenancePlanType: (type: string) => void;
  isSearchingMaintenancePlans: boolean;
  setIsSearchingMaintenancePlans: (open: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isDeletingMaintenancePlans: boolean;
  isExportingMaintenancePlans: boolean;
  maintenancePlansToExport: number[];
  setIsExportingMaintenancePlans: (open: boolean) => void;
  tablePageSize: number;
  setTablePageSize: (size: number) => void;
}

const MaintenancePlanSearchContext = createContext<MaintenancePlanSearchContext>({} as MaintenancePlanSearchContext);

export const MaintenancePlanSearchProvider: React.FC<any> = (props) => {
  const api = useApi();
  const { client } = useAuth();
  const [tablePageSize, setTablePageSize] = useState<number>(10);
  const [maintenancePlans, setMaintenancePlans] = useState<MaintenancePlan[]>([] as MaintenancePlan[]);
  const [maintenancePlansToExport, setMaintenancePlansToExport] = useState<number[]>([]);
  const [isDeletingMaintenancePlans, setIsDeletingMaintenancePlans] = useState<boolean>(false);
  const [isExportingMaintenancePlans, setIsExportingMaintenancePlans] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [MaintenancePlanType, setMaintenancePlanType] = useState<string>('All');
  const { addToast } = useToast();

  const {
    isLoading: isSearchingMaintenancePlans,
    refetch: refetchMaintenancePlans,
    data: dbMaintenancePlans = [] as MaintenancePlan[],
  } = useQuery<MaintenancePlan[], Error>(['maintenancePlan list'], async () =>
    api.post<MaintenancePlan[]>('/api/MaintenancePlan/List', {}).then(({ data }) => data)
  );

  useEffect(() => {
    if (dbMaintenancePlans.length > 0) {
      setMaintenancePlans(dbMaintenancePlans);
    }
  }, [dbMaintenancePlans]);

  const getMaintenancePlans = (text: string, type: string): void => {
    setSearchText(text);
    setMaintenancePlanType(type);
    const lowerSearchText = text.toLowerCase();
    setMaintenancePlans(
      dbMaintenancePlans.filter((MaintenancePlan) =>
        lowerSearchText === '' || lowerSearchText === null
          ? true
          : MaintenancePlan.assetReference?.toLowerCase().includes(lowerSearchText) ||
            MaintenancePlan.space?.toLowerCase().includes(lowerSearchText) ||
            MaintenancePlan.item?.toLowerCase().includes(lowerSearchText)
      )
    );
  };

  const exportMaintenancePlans = (MaintenancePlanIds: number[]): void => {
    setIsExportingMaintenancePlans(true);
    api.post('/api/MaintenancePlan/Export', { MaintenancePlanIds }, { responseType: 'blob' }).then(
      ({ data }) => {
        setIsExportingMaintenancePlans(false);
        downloadHandler(data, `${client} MaintenancePlans.xlsx`);
      },
      (error) => {
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const deleteMaintenancePlans = (MaintenancePlanIds: number[]): void => {
    setIsDeletingMaintenancePlans(true);
    api.post('/api/MaintenancePlan/BulkDelete', { MaintenancePlanIds }).then(
      ({ data }) => {
        setIsDeletingMaintenancePlans(false);
        setMaintenancePlans(maintenancePlans.filter((MaintenancePlan) => !MaintenancePlanIds.includes(MaintenancePlan.id)));
        addToast(`${data}`);
      },
      (error) => {
        setIsDeletingMaintenancePlans(false);
        addToast('Bulk Delete Failed', ToastType.Error);
      }
    );
  };

  useEffect(() => {
    getMaintenancePlans(searchText, MaintenancePlanType);
  }, [MaintenancePlanType, searchText]);

  return (
    <MaintenancePlanSearchContext.Provider
      value={{
        maintenancePlans,
        getMaintenancePlans,
        searchText,
        setSearchText,
        MaintenancePlanType,
        setMaintenancePlanType,
        isSearchingMaintenancePlans,
        currentPage,
        setCurrentPage,
        deleteMaintenancePlans,
        isDeletingMaintenancePlans,
        exportMaintenancePlans,
        isExportingMaintenancePlans,
        setIsExportingMaintenancePlans,
        maintenancePlansToExport,
        tablePageSize,
        setTablePageSize,
      }}
      {...props}
    />
  );
};

export const useMaintenancePlanSearch = (): MaintenancePlanSearchContext => useContext(MaintenancePlanSearchContext);
export default MaintenancePlanSearchProvider;
