import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '../../components/BasicTable';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { currencyFormat, percentFormat } from '../../Format';
import { ComponentAssumptionsWithHierarchy } from '../../models/ComponentAssumptions';
import { useApi } from '../../contexts/ApiContext';
import { ComponentWithAssumptionValues } from '../../models/ComponentWithAssumptionValues';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';

export const ComponentAssumptionsView: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [componentAssumptions, setComponentAssumptions] = useState<ComponentAssumptionsWithHierarchy>({
    components: [] as ComponentWithAssumptionValues[],
  } as ComponentAssumptionsWithHierarchy);

  useEffect(() => {
    api
      .get(`/api/Assumptions/GetComponentAssumptions?id=${id}`)
      .then(({ data }) => {
        setComponentAssumptions(data);
        setIsLoading(false);
      })
      .catch((error) => {
        addToast('Error retrieving Component Assumptions', ToastType.Error);
      });
  }, [id]);

  const columns = React.useMemo<ColumnDef<ComponentWithAssumptionValues>[]>(
    () => [
      {
        header: 'Asset ID',
        accessorKey: 'assetReference',
        meta: {
          headerClassName: 'px-2 py-2',
          className: 'px-2 py-2 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/assets/${row.original.assetId}`} className="btn-link">
            {row.original.assetReference}
          </Link>
        ),
      },
      {
        header: 'Component',
        accessorKey: 'name',
        meta: {
          headerClassName: 'px-2 py-2',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/components/${row.original.id || 0}`} className="btn-link">
            {row.original.name}
          </Link>
        ),
      },
      {
        header: 'Unit Rate',
        accessorKey: 'unitRate',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string => currencyFormat.format(row.original.unitRate),
      },
      {
        header: 'Locality Factor',
        accessorKey: 'localityFactorPct',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string => percentFormat.format(row.original.localityFactorPct),
      },
      {
        header: 'Short Life / Long Life',
        accessorKey: 'longLifePct',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string =>
          `${percentFormat.format(1 - row.original.longLifePct)} / ${percentFormat.format(row.original.longLifePct)}`,
      },
      {
        header: 'UL Short',
        accessorKey: 'ulShortMax',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string => `${row.original.ulShortMin} - ${row.original.ulShortMax} yrs`,
      },
      {
        header: 'UL Long',
        accessorKey: 'ulLong',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string => `${row.original.ulLong} yrs`,
      },
      {
        header: 'RV Short / Long',
        accessorKey: 'rvPctShort',
        meta: {
          headerClassName: 'px-2 py-2 text-right',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string =>
          `${percentFormat.format(row.original.rvPctShort)} / ${percentFormat.format(row.original.rvPctLong)}`,
      },
      {
        header: 'Depr Policy',
        accessorKey: 'depreciationPolicy',
        meta: {
          headerClassName: 'px-2 py-2 text-left',
          className: 'px-2 py-2 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: '',
        accessorKey: 'id',
        meta: {
          className: 'px-2 py-2 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element | null => {
          if (!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass)) {
            return (
              <Link className="btn-link" to={`/components/${row.original.id || 0}/edit`}>
                edit
              </Link>
            );
          }
          return null;
        },
      },
    ],
    [readOnlyAssetClasses]
  );

  const {
    componentsCount,
    assetClass,
    assetType,
    assetSubType,
    componentName,
    componentType,
    componentSubType,
    unitRate,
    localityFactorPct,
    longLifePct,
    ulShortMin,
    ulShortMax,
    ulLong,
    rvPctLong,
    rvPctShort,
    depreciationPolicy,
    obsolescenceProfile,
    components,
  } = componentAssumptions;

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Assumption</p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Component Assumptions</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && (
                    <Link to={`/componentassumptions/${id}/edit`} className="btn btn-primary">
                      Edit
                    </Link>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Number of Components</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{componentsCount}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Class</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetClass}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Sub Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetSubType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Component Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{componentName}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Component Sub Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{componentType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Component Sub Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{componentSubType}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Costs</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Unit Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{currencyFormat.format(unitRate)}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Locality Factor</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {percentFormat.format(localityFactorPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Long Life Split</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{percentFormat.format(longLifePct)}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">UL Short</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {ulShortMin} - {ulShortMax} years
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">UL Long</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{ulLong} years</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Residual Value Short</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{percentFormat.format(rvPctShort)}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Residual Value Long</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{percentFormat.format(rvPctLong)}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Depreciation Policy</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{depreciationPolicy}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Obsolescence Profile</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{obsolescenceProfile}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <BasicTable data={components} columns={columns} tablePageSize={10} />
      </div>
    </div>
  );
};
