import React, { useEffect, useState, useMemo } from 'react';
import { format, formatRelative } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { useApi } from '../../contexts/ApiContext';
import { Client, ClientSummary } from '../../models/Client';
import { BasicTable } from '../../components/BasicTable';
import { useAuth } from '../../contexts/AuthContext';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ToastType, useToast } from '../../contexts/ToastContext';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'dd/MM/yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token): void => formatRelativeLocale[token],
};

const ClientsList: React.FC<{}> = () => {
  const api = useApi();
  const { groupId } = useAuth();
  const [clients, setClients] = useState<ClientSummary[]>([] as ClientSummary[]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [clientId, setClientId] = useState<number>(0);
  const { addToast } = useToast();

  useEffect(() => {
    api
      .get(`/api/Client/List?GroupId=${groupId}&SearchText=${searchText}`)
      .then(({ data }) => {
        const strAscending = [...data].sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
        setClients(strAscending);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const openDeleteModal = (id: number): void => {
    setClientId(id);
    setIsDeleteModalOpen(true);
  };

  const columns = useMemo<ColumnDef<ClientSummary>[]>(
    () => [
      {
        header: 'Name ',
        accessorKey: 'displayName',
        meta: {
          className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link className="btn-link" to={`/clients/${row.original.id}`}>
            {row.original.displayName}
          </Link>
        ),
      },
      {
        header: 'Contact',
        accessorKey: 'contactName',
        meta: {
          className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Email',
        accessorKey: 'contactEmail',
        meta: {
          className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Expiry',
        accessorKey: 'expiry',
        meta: {
          className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${format(new Date(row.original.expiry || new Date()), 'dd/MM/yyyy')}`,
      },
    ],
    []
  );

  type FormData = {
    searchText: string;
  };

  const {
    handleSubmit,

    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(() => {
    api
      .get(`/api/Client/List?GroupId=${groupId}&SearchText=${searchText}`)
      .then(({ data }) => {
        const strAscending = [...data].sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
        setClients(strAscending);
      })
      .catch((error) => {
        console.log('error', error);
      });
  });

  const removeClient = (): void => {
    console.log('clientId', clientId);
    // api
    //   .post(`/api/Client/Delete`, { clientId })
    //   .then(({ data }) => {
    //     setClients(data);
    //     addToast(`Successfully deleted user`);
    //   })
    //   .catch((error) => {
    //     addToast(`Unable to remove user`, ToastType.Error);
    //   });
  };

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Clients</h1>
        </div>
      </div>
      <div className="m-4 bg-white shadow overflow-hidden sm:rounded-md flex ">
        <form onSubmit={onSubmit} className="flex-grow m-2">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="flex my-1 ml-1 relative min-w-max ">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              {/* Heroicon name: search */}
              <svg
                className="mr-3 h-4 w-4 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              name="searchText"
              onChange={(e): void => setSearchText(e.target.value)}
              value={searchText}
              className="mt-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
              placeholder="Search Clients"
            />
            <input type="submit" value="Search" className="m-1 btn btn-primary" />
          </div>
        </form>
      </div>
      <div className="mx-4 pt-4">
        <BasicTable columns={columns} data={clients} />
        {/* <JobModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} /> */}
        {searchText.length > 0 && clients.length <= 0 && (
          <div className="text-center mt-14 pt-3 pb-6">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No Clients Found</h3>
            <p className="mt-1 text-sm text-gray-500">Please create a new client or revise search parameters</p>
          </div>
        )}
      </div>
      <DeleteConfirmation
        itemType="Client"
        isOpen={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        deleteAction={removeClient}
      />
    </>
  );
};

export default ClientsList;
