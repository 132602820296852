import { format } from 'date-fns';
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import AddUserModal from '../../components/AddUserModal';
import { BasicTable } from '../../components/BasicTable';
import { ClientSummary } from '../../models/Client';
import { Group } from '../../models/Group';
import { useApi } from '../../contexts/ApiContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import { ColumnDef } from '@tanstack/react-table';

const GroupView: React.FC<{}> = () => {
  const api = useApi();
  const { id = '' } = useParams<{ id: string }>();
  const { roles } = useAuth();

  // const [group, setGroup] = useState<Group>({} as Group);
  const { addToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [setAdmin, setIsAdmin] = useState<boolean>(false);

  const {
    refetch,
    isFetching,
    data: group = {} as Group,
  } = useQuery<Group, Error>(['Group', id], async () => api.get(`/api/Group/Get?id=${id}`).then(({ data }) => data));

  const removeAdministrator = (userId: number): void => {
    api
      .post(`/api/Group/RemoveAdministrator`, { userId, groupId: id })
      .then(({ data }) => {
        refetch();
        addToast(`Successfully removed administrator`);
      })
      .catch((error) => {
        addToast(`Unable to remove administrator`, ToastType.Error);
      });
  };

  const columns = useMemo<ColumnDef<ClientSummary>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          className: 'px-4 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link className="btn-link" to={`/clients/${row.original.id}`}>
            {row.original.name}
          </Link>
        ),
      },
      {
        header: 'Contact',
        accessorKey: 'contactName',
        meta: {
          className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Email',
        accessorKey: 'contactEmail',
        meta: {
          className: 'px-4 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
    ],
    []
  );

  if (isFetching) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading</p>
      </div>
    );
  }

  const { id: groupId, name, subscriptionName, subscriptionId, contact, clients, administrator } = group;

  return (
    <>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Group Information</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link to={`/groups/${id}/edit`} className="btn btn-primary">
                    Edit
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Administrator</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {administrator ? (
                    <>
                      <Link to={`/users/${administrator.id}`} className="btn btn-link mr-3">
                        {administrator.firstName} {administrator.lastName}
                      </Link>

                      {roles.includes('Administrator') && (
                        <button
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                          onClick={(): void => removeAdministrator(administrator.id)}
                        >
                          Remove
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={(): void => {
                        setIsAdmin(true);
                        setIsModalOpen(true);
                      }}
                      className="btn btn-secondary"
                    >
                      Add User
                    </button>
                  )}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subscription</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Link to={`/subscriptions/${subscriptionId}`} className="btn-link">
                    {subscriptionName}
                  </Link>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Clients</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link to={`/groups/${id}/clients/0/edit`} className="btn btn-link">
                    Add Client
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 bg-white">
            <div className="pb-6 align-middle inline-block min-w-full">
              <BasicTable columns={columns} data={clients} tablePageSize={5} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Postion</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.position}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.email}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.phone}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Address Line 1</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.addressLine1}</dd>
              </div>
              {contact?.address?.addressLine2 && (
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address Line 2</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.addressLine2}</dd>
                </div>
              )}
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Suburb</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.suburb}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">City</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.city}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">State / Province</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.state}</dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Postcode / Zip</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contact?.address?.postCode}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      {/* <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Documents</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link to={`/groups/${id}/edit`} className="btn btn-link">
                    Add Document
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <svg
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="ml-2 flex-1 w-0 truncate">AVP_Valuers_Signed_Contract.pdf</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Download
                        </a>
                        <span className="mx-4 text-gray-300">|</span>
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Remove
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 rounded-md shadow overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  <Link to={`/groups/${id}/edit`} className="btn btn-link">
                    Add Note
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5  sm:px-6">
                <div>
                  <div className="flow-root mt-6">
                    <ul className="-my-5 divide-y divide-gray-200">
                      <li className="py-5">
                        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                          <div className="my-1 text-sm text-gray-400 line-clamp-2">20 Nov 2020</div>

                          <h3 className="text-sm font-semibold text-gray-800">
                            <a href="/" className="hover:underline focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              Client is not renewing
                            </a>
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                            Client has emailed to inform us they will not be using our services when the current license
                            expires
                          </p>
                        </div>
                      </li>

                      <li className="py-5">
                        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                          <div className="my-1 text-sm text-gray-400 line-clamp-2">30 July 2020</div>
                          <h3 className="text-sm font-semibold text-gray-800">
                            <a href="/" className="hover:underline focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              Client called and inquired about Group costs
                            </a>
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                            Peter was interested in discounts over long term
                          </p>
                        </div>
                      </li>

                      <li className="py-5">
                        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                          <div className="my-1 text-sm text-gray-400 line-clamp-2">3 May 2020</div>

                          <h3 className="text-sm font-semibold text-gray-800">
                            <a href="/" className="hover:underline focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              Client Inquiry
                            </a>
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">Was not sure about the product</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-6">
                    <a
                      href="/"
                      className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    >
                      More
                    </a>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div> */}
      <AddUserModal
        isOpen={isModalOpen}
        closeModal={(): void => setIsModalOpen(false)}
        hasUpdated={(): void => setIsUpdated(true)}
        groupAdmin
        groupId={groupId}
        setAdmin={setAdmin}
      />
    </>
  );
};

export default GroupView;
