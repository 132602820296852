import React, { useEffect } from 'react';
import { NavLink, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';

const Valuation: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: asset = {} as Asset } = useAssetById(parseInt(id));

  const { marketApproach, incomeApproach } = asset;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="">
        <div className="border-b border-gray-200">
          <div className="max-w-5xl mx-auto px-4 sm:px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <NavLink
                to="comparison"
                className={({ isActive }) =>
                  `w-1/2 ${
                    marketApproach != undefined && 'w-1/3'
                  } text-center text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                    isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                  }`
                }
                aria-current="page"
              >
                Summary
              </NavLink>
              <NavLink
                to="detail"
                className={({ isActive }) =>
                  `w-1/2 ${
                    marketApproach != undefined && 'w-1/3'
                  } text-center text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                    isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                  }`
                }
                aria-current="page"
              >
                Detail
              </NavLink>
              {marketApproach && (
                <NavLink
                  to="market"
                  className={({ isActive }) =>
                    `w-1/2 ${
                      marketApproach != undefined && 'w-1/3'
                    } text-center text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                      isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                    }`
                  }
                  aria-current="page"
                >
                  Market
                </NavLink>
              )}
              {incomeApproach && (
                <NavLink
                  to="income"
                  className={({ isActive }) =>
                    `w-1/2 ${
                      incomeApproach != undefined && 'w-1/3'
                    } text-center text-gray-500 hover:text-gray-700 hover:border-gray-300 xl:whitespace-nowrap py-4 px-1 font-medium text-sm ${
                      isActive && 'border-b-2 border-indigo-500 hover:border-indigo-500'
                    }`
                  }
                  aria-current="page"
                >
                  Income
                </NavLink>
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default Valuation;
