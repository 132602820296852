export const downloadHandler = (data: any, fileName: string): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeTimezoneOffset = (date: Date): Date => new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export function getEnumKeys<T extends string, TEnumValue extends string | number>(enumVariable: { [key in T]: TEnumValue }) {
  return Object.keys(enumVariable) as Array<T>;
}

export function convertDate(regdate: string) {
  return new Date(regdate.replace(/(\d+[/])(\d+[/])/, '$2$1'));
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

export const bulkUploadImages = async (files): Promise<string[]> => {
  const imageStringArray = [] as string[];
  for (const file of files) {
    const base64 = await convertToBase64(file);
    const result = typeof base64 === 'string' ? base64 : '';
    imageStringArray.push(result);
  }
  return imageStringArray;
};
