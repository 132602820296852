
export enum ContactType {
  ExternalPropertyManager = 'External Property Manager',
  Landlord = 'Landlord',
  InternalPropertyManager = 'Internal Property Manager',
  Tenant = 'Tenant',
}
export interface Tenant extends LeaseContact {
  isPrimary: boolean;
}

export interface Landlord extends LeaseContact {
  headLeaseName: string;
}

export interface LeaseContact {
  id: number;
  contactType: ContactType;
  name: string;
  email?: string;
  phone?: string;
  tradingName?: string;
}

export interface Lease {
  id: number;
  name: string;
  assetReference: string;
  tenants: Tenant[];
  landlord: Landlord;
  internalPropertyManager: LeaseContact;
  externalPropertyManager: ExternalPropertyManager;
  assetId: number;
  leaseType: string;
  leaseCategory: string;
  originalExecutedLeaseHeldBy: string;
  ownedBy?: string;
  isRegisteredWithTitlesOffices?: boolean;
  executed: boolean;
  leaseStartDate?: Date;
  rentStartDate?: Date;
  outgoingExpenseItem?: string;
  outgoingExpense: string;
  leaseExpiryDate?: Date;
  takeUpOptionMonths?: number;
  takeUpOptionDate?: Date;
  leaseOptionExpiries?: LeaseOptionExpiry[];
  makeGoodClause?: boolean;
  makeGood?: string;
  commencementYears?: number;
  guarantee: Guarantee;
  leaseInsurance: LeaseInsurance;
  occupancy: string;
  occupancyType?: string;
  // occupancyTypeDetail: string;
  area?: number;
  isGross?: boolean;
  annualRent: number;
  monthlyRent?: number; // Is this Necessary
  sqmRent?: number; // is this necessary
  tenancyId?: string; // Reference
  reviews: Review[];
  addressLine1?: string;
  suburb?: string;
}

// export interface LeaseTerms {
//   leaseStartDate?: Date;
//   rentStartDate?: Date;
//   outgoingExpenseItem?: string;
//   outgoingExpense: string;
//   leaseExpiryDate?: Date;
//   takeUpOptionMonths?: number;
//   takeUpOptionDate?: Date;
//   leaseOptionExpiries?: LeaseOptionExpiry[];
//   makeGoodClause?: boolean;
//   makeGood?: string;
//   commencementYears?: number;
//   commencementMonths?: number; // is this necessary
// }

export interface Review {
  frequency?: string;
  year?: number;
  date?: Date;
  note?: string;
  type?: string;
}

export interface Guarantee {
  type?: string;
  heldBy?: string;
  location?: string;
  expiryDate?: Date;
  isBondEqualRent?: string;
  date?: Date;
  amount?: number;
}

export interface LeaseInsurance {
  currentInsurer?: string;
  type?: string;
  start?: Date;
  end?: Date;
}



export interface ExternalPropertyManager extends LeaseContact {
  appointedDate?: Date;
  reviewDate?: Date;
  fee?: string;
}


export interface LeaseOptionExpiry {
  date: Date;
}

export interface LeaseDto {
  id: number;
  assetId: number;
  assetReference: string;
  name: string;
  leaseType?: string;
  leaseCategory?: string;
  occupancy?: string;
  occupancyType?: string;
  area: number;
  annualRent: number;
  isGross: boolean;
  isRegisteredWithTitlesOffice: boolean;
  originalExecutedLeaseHeldBy: string;
  ownedBy: string;
  leaseStartDate?: Date;
  leaseExpiryDate?: Date;
  rentStartDate?: Date;
  outgoingExpenseItem: string;
  outgoingExpense: string;
  takeUpOptionMonths: number;
  takeUpOptionDate: Date;
  leaseOptionExpiries: LeaseOptionExpiry[];
  isMakeGoodClause: boolean;
  makeGood: string;
  guarantee: Guarantee;
  currentInsurer: string;
  insuranceType?: string;
  insuranceStartDate?: Date;
  insuranceEndDate?: Date;
  reviews: Review[];
  tenants: Tenant[];
  landlord: Landlord;
  internalPropertyManager: LeaseContact;
  externalPropertyManager: ExternalPropertyManager;
  addressLine1: string;
  suburb: string;
  city: string;
  state: string;
  postcode: string;
}

export const LeaseToDto = (lease: Lease): LeaseDto =>
  ({
    id: lease.id ?? 0,
    assetReference: lease.assetReference,
    assetId: lease.assetId ?? 0,
    leaseType: lease.leaseType ?? 'Retail',
    leaseCategory: lease.leaseCategory ?? 'Lease',
    occupancy: lease.occupancy ?? 'Factory',
    occupancyType: lease.occupancyType ?? 'Whole Building',
    area: lease.area ?? 99,
    annualRent: lease.annualRent ?? 345345,
    isGross: lease.isGross ?? true,
    isRegisteredWithTitlesOffice: lease.isRegisteredWithTitlesOffices,
    originalExecutedLeaseHeldBy: lease.originalExecutedLeaseHeldBy ?? 'test',
    ownedBy: lease.ownedBy ?? 'test',
    leaseStartDate: lease.leaseStartDate ?? new Date(),
    leaseExpiryDate: lease.leaseExpiryDate ?? new Date(),
    rentStartDate: lease.rentStartDate ?? new Date(),
    outgoingExpenseItem: lease.outgoingExpenseItem ?? 'test',
    outgoingExpense: lease.outgoingExpense ?? '3432%',
    takeUpOptionMonths: lease.takeUpOptionMonths ?? 6,
    takeUpOptionDate: lease.takeUpOptionDate ?? new Date(),
    leaseOptionExpiries: lease.leaseOptionExpiries ?? [],
    isMakeGoodClause: lease.makeGoodClause ?? true,
    makeGood: lease.makeGood ?? 'test',
    guarantee: lease.guarantee ?? {},
    currentInsurer: lease.leaseInsurance?.currentInsurer ?? 'test',
    insuranceType: lease.leaseInsurance?.type ?? 'Building',
    insuranceStartDate: lease.leaseInsurance?.start ?? new Date(),
    insuranceEndDate: lease.leaseInsurance?.end ?? new Date(),
    reviews: lease.reviews ?? [],
    tenants: lease.tenants ?? [],
    landlord: lease.landlord ?? {},
    internalPropertyManager: lease.internalPropertyManager ?? {},
    externalPropertyManager: lease.externalPropertyManager ?? {},
    addressLine1: lease.addressLine1 ?? 'test',
  } as LeaseDto);
