import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline';
import { BBox, GeoJSON } from 'geojson';
import { ReactComponent as LocationMarker } from '../icons/LocationMarker.svg';
import { Asset } from '../models/Asset';
import { ReactComponent as ImageNotFound } from '../icons/ImageNotFound.svg';
import { currencyFormat } from '../Format';
import { useAssetById } from '../hooks/UseAsset';
import AssetInfoContainer from './AssetInfoContainer';

interface ClusterMarkerProps {
  lat: number;
  lng: number;
  pointCount: number;
  leaves: any[];
  valuerMode: boolean;
  zoomToCluster: () => void;
}

const ClusterMarker: React.FC<ClusterMarkerProps> = ({ pointCount, zoomToCluster, leaves, valuerMode }): JSX.Element => {
  // <LocationMarker title={name} className="h-6 w-6 text-red-600" />
  // <button
  //   className="inline-flex items-center justify-center w-6 h-6 rounded-xl bg-red-600 p-3 text-xs font-medium text-white"
  //   onClick={zoomToCluster}
  // >
  //   {pointCount}
  // </button>
  const assets = leaves.map(
    (leaf) =>
      ({
        ...leaf.properties.asset,
      } as Asset)
  );

  return (
    <Popover className="relative">
      <PopoverButton>
        <div className="w-6 h-6 bg-red-600 rounded-full relative border-2 border-white shadow-2xl">
          <span className="flex absolute h-4 w-4 top-0 left-1 ml-1 mb-2 -translate-y-1/4 translate-x-1/4 block">
            <span className="relative flex justify-center items-center rounded-full h-4 w-4 bg-gray-500 text-white text-xs">
              {pointCount}
            </span>
          </span>
        </div>
      </PopoverButton>

      <PopoverPanel
        transition
        anchor={{ to: 'bottom start', gap: '8px' }}
        className="inline-block bg-white h-48 w-72 text-sm text-gray-500 transition duration-200 bg-white border border-gray-300 rounded-lg shadow-sm  ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 flex flex-row justify-between items-center">
          <p className="text-sm leading-6 text-gray-600 sm:truncate">{assets.length} assets at this location</p>
          <button
            type="button"
            className="text-blue-600 bg-gray-100 inline-flex items-center gap-x-2.5 py-2 text-sm rounded-md font-semibold"
            onClick={zoomToCluster}
          >
            <MagnifyingGlassPlusIcon className="h-5 w-5 text-blue-600 hover:text-blue-900" aria-hidden="true" />
          </button>
        </div>
        <div className="rounded-t-lg">
          {assets.map((asset) => (
            <AssetInfoContainer asset={asset} valuerMode={valuerMode} />
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export default ClusterMarker;
