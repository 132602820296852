import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { BasicTable, EnhancedColumn } from '../../components/BasicTable';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { currencyFormat, percentFormat } from '../../Format';
import { AssetWithInsurance } from '../../models/AssetSummary';
import { InsuranceAssumptionsWithAssets, InsuranceAssumptions } from '../../models/InsuranceAssumptions';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { ColumnDef } from '@tanstack/react-table';

export const InsuranceAssumptionsView: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();
  const { readOnlyAssetClasses, checkAssetClassEditable } = useClient();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [insuranceAssumptions, setInsuranceAssumptions] = useState<InsuranceAssumptionsWithAssets>(
    {} as InsuranceAssumptionsWithAssets
  );

  useEffect(() => {
    api
      .get(`/api/Assumptions/GetInsuranceAssumptions?id=${id}`)
      .then(({ data }) => {
        setInsuranceAssumptions(data);
        setIsLoading(false);
      })
      .catch((error) => {
        addToast('Error retrieving Insurance Assumptions', ToastType.Error);
      });
  }, [id]);

  const columns = React.useMemo<ColumnDef<AssetWithInsurance>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <Link to={`/assets/${row.original.id || 0}`} className="btn-link">
            {row.original.name}
          </Link>
        ),
      },
      {
        header: 'Asset Id',
        accessorKey: 'reference',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Lead Build Months',
        accessorKey: 'insurance.leadBuildMonths',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Demolition Months',
        accessorKey: 'insurance.demoMonths',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Professional Fees',
        accessorKey: 'insurance.professionalFeesPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.insurance.professionalFeesPct)}`,
      },
      {
        header: 'Escalation Factor',
        accessorKey: 'insurance.escalationFactorPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.insurance.escalationFactorPct)}`,
      },
      {
        header: 'Debri Removal Rate',
        accessorKey: 'insurance.debrisRemovalPct',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${percentFormat.format(row.original.insurance.debrisRemovalPct)}`,
      },
      {
        header: 'Debri Removal Minimum',
        accessorKey: 'insurance.debrisRemovalMinimum',
        meta: {
          headerClassName: 'px-3.5 py-3 text-right',
          className: 'px-3.5 py-3 text-right whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${currencyFormat.format(row.original.insurance.debrisRemovalMinimum)}`,
      },
      {
        header: '',
        accessorKey: 'edit',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        cell: ({ row }): JSX.Element | null => {
          if (!roles.includes(Roles.Viewer) && checkAssetClassEditable(row.original.assetClass)) {
            return (
              <Link className="btn-link" to={`/insuranceassumptions/${row.original.id || 0}/edit`}>
                edit
              </Link>
            );
          }
          return null;
        },
      },
    ],
    [readOnlyAssetClasses]
  );

  const {
    assetClass,
    assetType,
    assetSubType,
    demoMonths,
    leadBuildMonths,
    escalationFactorPct,
    professionalFeesPct,
    debrisRemovalPct,
    debrisRemovalMinimum,
    assetsCount,
    assets = [] as AssetWithInsurance[],
  } = insuranceAssumptions;

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Assumption</p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Insurance Assumptions</h3>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <span className="shadow-sm">
                  {!roles.includes(Roles.Viewer) && checkAssetClassEditable(assetClass) && (
                    <Link to={`/insuranceassumptions/${id}/edit`} className="btn btn-primary">
                      Edit
                    </Link>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Number of Assets</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetsCount}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Class</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetClass}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetType}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset Sub Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assetSubType}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Costs</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Demolition Months</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{demoMonths}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Lead Build Months</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{leadBuildMonths}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Professional Fees</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {percentFormat.format(professionalFeesPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Escalation Factor</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {percentFormat.format(escalationFactorPct)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Debris Removal</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {percentFormat.format(debrisRemovalPct)}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Minimum</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-4">
                  {currencyFormat.format(debrisRemovalMinimum)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="m-4">
        <BasicTable data={assets} columns={columns} />
      </div>
    </div>
  );
};
