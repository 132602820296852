import React, { useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldArrayWithId,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import {
  TrashIcon,
  PencilSquareIcon,
  ArrowTopRightOnSquareIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Link, NavLink } from 'react-router-dom';
import { ComponentReplacementCostEdit } from './ComponentReplacementCostEdit';
import { currencyFormat } from '../Format';
import { Asset, ComponentHierarchyItem, DropDownItem } from '../models/Asset';
import { ComponentAssumptions } from '../models/ComponentAssumptions';
import { useApi } from '../contexts/ApiContext';
import { ComponentReplacementCost } from '../models/ReplacementCostSummary';
import { AssetAssumptions } from '../models/AssetAssumptions';
import CalculateScoreModal from './Asset/QuickEdit/CalculateScoreModal';
import { Component } from '../models/Component';
import { Job } from '../models/Job';

type ComponentEditProps = {
  index: number;
  assetAssumption?: AssetAssumptions;
  control: Control<Asset, object>;
  typeError?: string;
  subTypeError?: string;
  dateError?: string;
  watch: UseFormWatch<Asset>;
  register: UseFormRegister<Asset>;
  remove: (index?: number | number[] | undefined) => void;
  field: FieldArrayWithId<Asset, 'components', 'id'>;
  componentList: ComponentHierarchyItem[];
  job: Job;
  setComponentList: (componentList: ComponentHierarchyItem[]) => void;
  showDates: boolean;
  getValues: UseFormGetValues<Asset>;
  setValue: UseFormSetValue<Asset>;
  reset: UseFormReset<Asset>;
  resetField: UseFormResetField<Asset>;
  isDirectCost: boolean;
};

function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}
export const ComponentEdit: React.FC<ComponentEditProps> = ({
  assetAssumption,
  index,
  register,
  control,
  typeError,
  subTypeError,
  dateError,
  watch,
  field,
  setComponentList,
  remove,
  componentList,
  job,
  showDates,
  getValues,
  setValue,
  reset,
  resetField,
  isDirectCost,
}) => {
  const api = useApi();
  const watchComponentNameId = watch(`components.${index}.nameId`) || 0;
  const watchComponentApportionment = watch(`components.${index}.apportionmentPct`) || 0;
  const watchComponentTypeId = watch(`components.${index}.typeId`) || 0;
  const watchComponentSubTypeId = watch(`components.${index}.subTypeId`) || 0;
  const replacementCosts = watch(`replacementCosts`) || [];

  const compReplacementCosts = watch(`components.${index}.replacementCosts`) || [];
  const [componentTypeList, setComponentTypeList] = useState<ComponentHierarchyItem[]>([]);
  const [componentSubTypeList, setComponentSubTypeList] = useState<ComponentHierarchyItem[]>([]);
  const [componentAssumptions, setComponentAssumptions] = useState<ComponentAssumptions>({} as ComponentAssumptions);
  const [isCalculateScoreModalOpen, setIsCalculateScoreModalOpen] = useState<boolean>(false);
  const [component, setComponent] = useState<Component>({} as Component);

  const {
    fields: crcFields,
    append: appendCRC,
    remove: removeCRC,
  } = useFieldArray({
    control,
    name: `components.${index}.replacementCosts`,
  });

  useEffect(() => {
    if (watchComponentNameId > 0) {
      api
        .get(`/api/ComponentHierarchy/GetComponentHierarchyList?componentNameId=${watchComponentNameId}`)
        .then(({ data }) => {
          setComponentTypeList([{ id: 0, name: '' } as ComponentHierarchyItem].concat(data.filter((n) => n)));
          setValue(`components.${index}.typeId`, watchComponentTypeId);
        });
    }
  }, [watchComponentNameId]);

  useEffect(() => {
    if (watchComponentTypeId > 0) {
      api
        .get(`/api/ComponentHierarchy/GetComponentHierarchyList?componentTypeId=${watchComponentTypeId}`)
        .then(({ data }) => {
          setComponentSubTypeList(data);
        });
    }
  }, [watchComponentTypeId]);

  const prevLists = usePrevious<{
    componentList: ComponentHierarchyItem[];
    componentTypeList: ComponentHierarchyItem[];
    componentSubTypeList: ComponentHierarchyItem[];
  }>({
    componentList,
    componentTypeList,
    componentSubTypeList,
  });

  useEffect(() => {
    if (
      getValues(`components.${index}.typeId`) !== 0 &&
      prevLists?.componentTypeList.length === 0 &&
      componentTypeList.length > 0
    ) {
      resetField(`components.${index}.typeId`);
    }
  }, [componentTypeList]);

  useEffect(() => {
    if (Object.keys(componentSubTypeList).length > 0) {
      let defaultSubType = componentSubTypeList.find((lookup) => lookup.name === 'Typical Life');
      if (defaultSubType === undefined) {
        defaultSubType = componentSubTypeList.at(0);
      }
      const currentSubTypeId = getValues(`components.${index}.subTypeId`);
      if (currentSubTypeId === 0 || currentSubTypeId === null || currentSubTypeId === undefined) {
        setValue(
          `components.${index}.subTypeId`,
          defaultSubType && typeof defaultSubType.id === 'number' ? defaultSubType.id : 0
        );
        api
          .get(
            `/api/Assumptions/GetComponentAssumptionsBySubType?ComponentSubTypeId=${getValues(
              `components.${index}.subTypeId`
            )}`
          )
          .then(({ data }) => {
            setComponentAssumptions(data !== undefined ? data : ({} as ComponentAssumptions));
            setValue(`components.${index}.componentAssumptionsId`, data?.id || 0);
          });
      }
    }
  }, [componentSubTypeList]);

  const totalCRCGross = compReplacementCosts.reduce((previousValue, currentValue) => previousValue + currentValue.gross, 0);
  const totalRCGross = replacementCosts.reduce((previousValue, currentValue) => previousValue + currentValue.gross, 0);

  const onChangeApportionment = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!isDirectCost) {
      const componentGross = (totalRCGross * +e.target.value) / 100;
      const apportionmentTotal = getValues(`components`)
        .map((component, idx) => (idx === index ? +e.target.value / 100 : component.apportionmentPct / 100))
        .reduce((prev, curr) => prev + curr, 0);
      setValue(`components.${index}.apportionmentPct`, +e.target.value);
      setValue(`apportionmentTotal`, apportionmentTotal);
      setValue(`components.${index}.gross`, componentGross);
    }
  };

  const onChangeSubType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    api.get(`/api/Assumptions/GetComponentAssumptionsBySubType?ComponentSubTypeId=${+e.target.value}`).then(({ data }) => {
      setValue(`components.${index}.componentAssumptionsId`, data?.id);
    });
  };

  const onChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setValue(`components.${index}.subTypeId`, 0);
  };

  const openCalculateScoreModal = (): void => {
    setComponent(getValues(`components.${index}`));
    setIsCalculateScoreModalOpen(true);
  };

  useEffect(() => {
    // const compAssumptions =
    // setComponentAssumptions(compAssumptions?.);
    if (watchComponentSubTypeId > 0) {
      api
        .get(`/api/Assumptions/GetComponentAssumptionsBySubType?ComponentSubTypeId=${watchComponentSubTypeId}`)
        .then(({ data }) => {
          setComponentAssumptions(data !== undefined ? data : ({} as ComponentAssumptions));
        });
    }
  }, [watchComponentSubTypeId]);

  useEffect(() => {
    if (isDirectCost) {
      setValue(`components.${index}.gross`, totalCRCGross);
    }
  }, [totalCRCGross]);

  return (
    <>
      <tr key={field.id} className={`my-3 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
          <input type="hidden" {...register(`components.${index}.id` as const, { valueAsNumber: true })} />
          <div className="flex space-x-2">
            <input type="hidden" {...register(`components.${index}.nameId` as const, { valueAsNumber: true })} />
            <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
              {getValues(`components.${index}.id`) > 0 ? (
                <Link
                  to={`/components/${getValues(`components.${index}.id`)}`}
                  className="btn-link"
                  title={`View ${getValues(`components.${index}.name`)}`}
                >
                  {getValues(`components.${index}.name`)}
                </Link>
              ) : (
                getValues(`components.${index}.name`)
              )}
            </p>
          </div>
        </td>
        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
          <select
            {...register(`components.${index}.typeId` as const, {
              valueAsNumber: true,
              onChange: (e): void => {
                onChangeType(e);
              },
            })}
            key={field.id}
            // defaultValue={field.componentTypeId}
            // onChange={(e): Promise<void> => onChangeType(e)}
            className={`block w-full whitespace-normal rounded-md ${
              typeError ? 'border-rose-600' : ''
            } border-gray-300 bg-none text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:w-16 sm:px-0.5 sm:text-sm md:w-36 lg:w-52 lg:px-1`}
          >
            {componentTypeList.map((componentType) => (
              <option key={componentType.id} value={componentType.id}>
                {componentType.name}
              </option>
            ))}
          </select>
        </td>
        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
          <div className="flex flex-row justify-start items-center">
            <select
              key={field.id}
              {...register(`components.${index}.subTypeId` as const, {
                valueAsNumber: true,
                onChange: (e): void => {
                  onChangeSubType(e);
                },
              })}
              // defaultValue={field.componentSubTypeId}
              // onChange={(e): void => onChangeSubType(e)}
              className={`block w-full whitespace-normal rounded-md ${
                subTypeError ? 'border-rose-600' : ''
              } border-gray-300 bg-none text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:w-16 sm:px-0.5 sm:text-sm md:w-36 lg:w-52 lg:px-1`}
            >
              {componentSubTypeList.map((componentSubType) => (
                <option key={componentSubType.id} value={componentSubType.id}>
                  {componentSubType.name}
                </option>
              ))}
            </select>
            {componentAssumptions && isDirectCost && componentAssumptions?.id && componentAssumptions?.id > 0 && (
              <NavLink to={`/componentassumptions/${componentAssumptions?.id}`}>
                <ArrowTopRightOnSquareIcon className="h-4 w-4 text-indigo-600 ml-3 hover:text-indigo-900" />
              </NavLink>
            )}
            {watchComponentSubTypeId !== 0 && isDirectCost && !componentAssumptions && (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
                title="No associated component assumption"
              />
            )}
          </div>
        </td>
        <td className="sm:w-22 whitespace-nowrap flex justify-end px-3 py-2 text-right text-sm text-gray-500">
          {job.type === 'Comprehensive' ? (
            <input
              {...register(`components.${index}.consumptionScore` as const, { valueAsNumber: true })}
              type="number"
              step="any"
              onFocus={(e): void => e.target.select()}
              onWheel={(e): void => e.currentTarget.blur()}
              className="block rounded-md border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:w-12 sm:pr-4 sm:text-sm lg:w-16 lg:pr-5"
            />
          ) : (
            <div className="flex rounded-md shadow-sm sm:w-16 sm:text-sm lg:w-24">
              <div className="relative flex flex-grow items-stretch focus-within:z-10 ">
                <input
                  {...register(`components.${index}.consumptionScore` as const, { valueAsNumber: true })}
                  type="number"
                  step="any"
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-4 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                type="button"
                onWheel={(e): void => e.currentTarget.blur()}
                onClick={(): void => openCalculateScoreModal()}
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <WrenchScrewdriverIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-gray-400" />
              </button>
            </div>
          )}
        </td>

        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-12 lg:w-16">
          <div className="flex sm:space-x-1 lg:space-x-2">
            <div className="relative rounded-md shadow-sm ">
              <input
                {...register(`components.${index}.apportionmentPct` as const, {
                  valueAsNumber: true,
                  onChange: (e) => {
                    onChangeApportionment(e);
                  },
                })}
                defaultValue={field.apportionmentPct}
                type="number"
                onWheel={(e): void => e.currentTarget.blur()}
                disabled={isDirectCost}
                step="any"
                onFocus={(e): void => e.target.select()}
                className={`block rounded-md ${isDirectCost ? `disabled:opacity-50` : ''}
                border-gray-300 px-1 text-right focus:border-indigo-500 focus:ring-indigo-500 sm:w-12 sm:pr-4 sm:text-sm lg:w-16 lg:pr-5`}
                // onChange={onChangeApportionment}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-1 lg:pr-2">%</div>
            </div>
          </div>
        </td>

        <td className="whitespace-nowrap px-1 py-2 text-right text-sm text-gray-500 sm:w-20">
          {!isDirectCost
            ? currencyFormat.format(
                watchComponentApportionment &&
                  (replacementCosts.reduce((previousValue, currentValue) => previousValue + currentValue.gross, 0) *
                    +watchComponentApportionment.toFixed(2)) /
                    100
              )
            : currencyFormat.format(totalCRCGross)}
        </td>

        {showDates && (
          <>
            <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-20">
              <Controller
                control={control}
                name={`components.${index}.acquisitionDate` as const}
                render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
                  <ReactDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    name={`components.${index}.acquisitionDate` as const}
                    className="block rounded-md border-gray-300 focus:outline-none sm:w-20 sm:p-1 sm:text-sm"
                    selected={value}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </td>
            <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:w-20">
              <Controller
                control={control}
                name={`components.${index}.decommissionDate` as const}
                render={({ field: { onChange, onBlur, value, ref } }): JSX.Element => (
                  <ReactDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    name={`components.${index}.decommissionDate` as const}
                    className={`block rounded-md border-gray-300 ${
                      dateError ? 'border-rose-600' : ''
                    } focus:outline-none sm:w-20 sm:p-1 sm:text-sm`}
                    selected={value}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </td>
          </>
        )}

        <td className="whitespace-nowrap px-1 py-2 text-center text-sm font-medium">
          <button
            type="button"
            onClick={(): void => {
              remove(index);
              setValue(
                'apportionmentTotal',
                Math.round(getValues('components').reduce((prev, curr) => prev + curr.apportionmentPct, 0)) / 100
              );
              const newCompList = componentList.concat([
                {
                  id: field.nameId,
                  name: field.name,
                  apportionment: field.apportionmentPct,
                } as ComponentHierarchyItem,
              ]);
              setComponentList(newCompList);
            }}
          >
            <TrashIcon className="h-6 w-6 text-gray-400 hover:text-red-700" />
          </button>
        </td>
        <CalculateScoreModal
          component={component}
          isOpen={isCalculateScoreModalOpen}
          closeModal={(): void => setIsCalculateScoreModalOpen(false)}
        />
      </tr>
      {isDirectCost && (
        <td className="p-3" colSpan={7}>
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      L
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      W
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Area
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Qty
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Specified Unit Rate
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Adjusted Unit Rate ({componentAssumptions.unitRate})
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Local Adj
                    </th>

                    <th
                      scope="col"
                      className="px-2 py-3 text-right text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Gross
                    </th>

                    <th scope="col" className="relative px-3 py-3 text-right">
                      <button
                        type="button"
                        className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900"
                        onClick={(): void => {
                          appendCRC({
                            id: 0,
                            componentId: getValues(`components.${index}.id`) || 0,
                            name: 'Standard',
                            length: 1,
                            width: 1,
                            quantity: 1,
                            localityFactorPct: componentAssumptions?.localityFactorPct ?? 0,
                            indexationPct: assetAssumption?.accumulatedIndexTotal ?? 0,
                            unitRate: componentAssumptions?.unitRate ?? 0,
                            adjustedRate: componentAssumptions?.unitRate ?? 0,
                            adjustmentPct: 0,
                            gross: 0,
                          } as ComponentReplacementCost);
                        }}
                      >
                        <PlusIcon className="h-4 w-4" />
                      </button>

                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {crcFields.map((field, rcIndex) => (
                    <ComponentReplacementCostEdit
                      remove={removeCRC}
                      key={rcIndex}
                      {...{
                        index: rcIndex,
                        componentIndex: index,
                        // nameError:
                        //   errors !== undefined && errors.replacementCosts !== undefined
                        //     ? errors.replacementCosts[index]?.name?.message
                        //     : undefined,
                        // quantityError:
                        //   errors !== undefined && errors.replacementCosts !== undefined
                        //     ? errors.replacementCosts[index]?.quantity?.message
                        //     : undefined,
                        // lengthError:
                        //   errors !== undefined && errors.replacementCosts !== undefined
                        //     ? errors.replacementCosts[index]?.length?.message
                        //     : undefined,
                        // widthError:
                        //   errors !== undefined && errors.replacementCosts !== undefined
                        //     ? errors.replacementCosts[index]?.width?.message
                        //     : undefined,
                        field,
                        watch,
                        register,
                        getValues,
                        setValue,
                        control,
                        // defaultValues,
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </td>
      )}
    </>
  );
};
