import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ReactComponent as ImageNotFound } from '../icons/ImageNotFound.svg';
import { ReactComponent as LocationMarker } from '../icons/LocationMarker.svg';
import { Asset } from '../models/Asset';
import { useAssetById } from '../hooks/UseAsset';
import { currencyFormat, percentFormat } from '../Format';

interface MarkerProps {
  lat: number;
  lng: number;
  asset?: Asset;
  valuerMode: boolean;
}
const Marker: React.FC<MarkerProps> = ({ asset, valuerMode }): JSX.Element => {
  const { data: dbasset } = useAssetById(asset?.id || 0);
  const navigateLink = valuerMode ? `/assets/${asset?.id}/quickedit` : `/assets/${asset?.id || 0}`;
  return (
    // <LocationMarker title={name} className="h-6 w-6 text-red-600" />
    <Popover className="relative">
      <PopoverButton className="absolute top-1/2 left-1/2 pb-6 select-none transform -translate-x-1/2 -translate-y-1/2">
        {asset?.inspectionStatus === 'Complete' && (
          <LocationMarker title={asset?.name} className="h-6 w-6 text-purple-600" />
        )}
        {asset?.inspectionStatus === 'Inspected' && (
          <LocationMarker title={asset?.name} className="h-6 w-6 text-yellow-300" />
        )}
        {asset?.inspectionStatus === 'To Be Inspected' && (
          <LocationMarker title={asset?.name} className="h-6 w-6 text-red-600" />
        )}
        {asset?.inspectionStatus === 'Not Required' && (
          <LocationMarker title={asset?.name} className="h-6 w-6 text-grey-600" />
        )}
      </PopoverButton>

      <PopoverPanel
        transition
        anchor={{ to: 'bottom start' }}
        className="inline-block bg-white h-40 w-96 text-sm text-gray-500 transition duration-200 bg-white border border-gray-300 rounded-lg shadow-sm  ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg flex flex-row justify-between">
          <h3 className="font-semibold text-gray-900 dark:text-white">{asset?.name}</h3>
          <NavLink to={navigateLink}>
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-indigo-600 hover:text-indigo-900" />
          </NavLink>
        </div>
        <div className="flex flex-row py-1.5 px-2">
          {dbasset?.images[0]?.thumbnail ? (
            <img
              className="inline-block h-28 w-36 min-h-full rounded-md"
              src={dbasset?.images[0]?.thumbnail}
              alt={asset?.name}
            />
          ) : (
            <ImageNotFound className="h-14 w-14 min-h-full rounded-md text-grey-600" />
          )}
          <div className="px-3 py-2">
            <p>{dbasset?.reference}</p>
            <p>Gross: {currencyFormat.format(dbasset?.valuationMethod?.gross || 0)}</p>
            <p>CV: {currencyFormat.format(dbasset?.valuationMethod?.currentValue || 0)}</p>
            <p>
              Pct Gross:{' '}
              {percentFormat.format(
                dbasset?.valuationMethod?.currentValue && dbasset?.valuationMethod?.gross
                  ? (dbasset?.valuationMethod?.currentValue || 1) / (dbasset?.valuationMethod?.gross || 1)
                  : 0
              )}
            </p>
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export default Marker;
