import React, { useEffect, useState } from 'react';
import { format, formatRelative } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { Link, NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { SubscriptionSummary } from '../../models/Subscription';
import { useApi } from '../../contexts/ApiContext';
import { currencyFormat } from '../../Format';
import { BasicTable } from '../../components/BasicTable';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'dd/MM/yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token): void => formatRelativeLocale[token],
};

const SubscriptionList: React.FC<{}> = () => {
  const api = useApi();
  const [subscriptions, setSubscriptions] = useState<SubscriptionSummary[]>([] as SubscriptionSummary[]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    api
      .get(`/api/Subscription/List?SearchText=${searchText}`)
      .then(({ data }) => {
        console.log(`subs`, data);
        setSubscriptions(data);
      })
      .catch((error) => {});
  }, []);

  const columns = React.useMemo<ColumnDef<SubscriptionSummary>[]>(
    () => [
      {
        header: 'Name ',
        accessorKey: 'name',
        meta: {
          className: 'px-2 py-2.5 text-sm leading-5 font-medium sm:truncate',
        },
        cell: ({ row }): JSX.Element => (
          <div className="div">
            <Link to={`/subscriptions/${row.original.id || 0}`} className="btn-link">
              {row.original.name}
            </Link>
          </div>
        ),
      },
      {
        header: 'Start Date',
        accessorKey: 'start',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${row.original.start ? format(new Date(row.original.start), 'dd/MM/yyyy') : 'n/a'}`,
      },
      {
        header: 'Expiry Date',
        accessorKey: 'expiry',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${row.original.expiry ? format(new Date(row.original.expiry), 'dd/MM/yyyy') : 'n/a'}`,
      },
      {
        header: 'Due Date',
        accessorKey: 'due',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): string => `${format(new Date(row.original.due), 'dd/MM/yyyy')}`,
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Amount Due',
        accessorKey: 'fee',
        meta: {
          headerClassName: 'text-right',
          className: 'px-2 py-2.5 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): string => `${currencyFormat.format(row.original.fee)}`,
      },
      {
        header: '',
        accessorKey: 'id',
        meta: {
          className: 'px-4 py-2.5 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <NavLink className="btn-link" to={`/subscriptions/${row.original.id || 0}/edit`}>
            edit
          </NavLink>
        ),
      },
    ],
    []
  );
  type FormData = {
    searchText: string;
    type: string;
  };

  const { handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(() => {
    api
      .get(`/api/Subscription/List?SearchText=${searchText}`)
      .then(({ data }) => {
        setSubscriptions(data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  });

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Subscriptions</h1>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <span className="shadow-sm">
              <Link to="/subscriptions/0/edit" className="btn btn-link">
                Create Subscription
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="m-4 bg-white shadow overflow-hidden sm:rounded-md flex ">
        <form onSubmit={onSubmit} className="flex-grow m-2">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="flex my-1 ml-1 relative min-w-max">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              {/* Heroicon name: search */}
              <svg
                className="mr-3 h-4 w-4 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              name="searchText"
              onChange={(e): void => setSearchText(e.target.value)}
              value={searchText}
              className="mt-1 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-10"
              placeholder="Search Subscriptions"
            />

            <input type="submit" value="Search" className="m-1 btn btn-primary" />
          </div>
        </form>
      </div>
      <div className="mx-4 pt-4">
        <BasicTable columns={columns} data={subscriptions} />
      </div>
    </>
  );
};

export default SubscriptionList;
